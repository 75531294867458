import * as R from 'ramda';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';

import { Select } from 'components';
import { omitEmpty } from 'utils';
import { ISupportsFilter, ISearchFilter } from '../interfaces';
import SearchInput from './SearchInput';
import FiltersContainer from './FiltersContainer';
//import useGetAboveUnits from 'modules/Forms/queries/useGetAboveUnits';
import useGetAboveUnits from 'modules/Forms/queries/useGetListAboveUnits';
import { IAboveUnit } from 'modules/Forms/interfaces';
import strings from '../../../configs/strings';
import { ActionBtnStyles } from './../../../global-styles';

interface ISupportsFilterProps {
  onChange: (filter: Partial<ISupportsFilter>) => any;
}

const initialState: ISupportsFilter = {
  department: '',
  search: '',
};

const SupportsFilter: React.FC<ISupportsFilterProps> = ({ onChange }) => {
  const [filter, setFilter] = useState(initialState);
  const [searchInput, setSearchInput] = useState('');

  const { data: aboveUnits } = useGetAboveUnits({ list_name: 'department' });

  const onDepartmentChange = useCallback((department: string) => {
    setFilter(R.assoc('department', department));
  }, []);

  const onSearchChange = useCallback((searchFilter: Partial<ISearchFilter>) => {
    setFilter(R.mergeLeft(searchFilter));
  }, []);

  const departmentOptions = useMemo(
    () =>
      aboveUnits?.map((unit: IAboveUnit) => ({
        value: unit.name,
        label: unit.name,
      })) || [],
    [aboveUnits],
  );

  const resetAllFilters = () => {
    setSearchInput('');
    onSearchChange(initialState);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onChange(omitEmpty(filter));
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [filter, onChange]);

  return (
    <FiltersContainer>
      <Select
        id="support-department"
        label={strings.get('label.department')}
        options={departmentOptions}
        value={`${filter?.department}`}
        onChange={onDepartmentChange}
      />
      <SearchInput value={searchInput} setValue={setSearchInput} onChange={onSearchChange} />
      <Button
          sx={{
            height: '39px',
            ...ActionBtnStyles
          }}
          variant="contained"
          onClick={resetAllFilters}
        >
          {strings.get('btn.reset')}
      </Button>
    </FiltersContainer>
  );
};

export default SupportsFilter;
