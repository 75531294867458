import { Box, Chip, MenuItem, OutlinedInput } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import * as R from 'ramda';
import { useCallback, memo } from 'react';

import Select, { ISelectProps } from './Select';
import { IOption } from 'interfaces';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const parseValHelper = (value: ISelectChipsV2Props['value']) => value.map((option: string) => JSON.parse(option));

const getStyles = (value: IOption['value'], selectedValues: ISelectChipsV2Props['value']) => ({
  fontWeight: (selectedValues || [])?.findIndex((el: string) => el.includes(value)) === -1 ? 'normal' : 'bold'
});

export interface ISelectChipsV2Props extends Omit<ISelectProps, 'value' | 'onChange'> {
  value: string[];
  onChange: (values: string[]) => void;
}

const SelectChipsV2: React.FC<ISelectChipsV2Props> = ({
  id,
  label,
  options,
  value = [],
  onChange,
  formControlProps = {},
  selectProps = {},
}) => {

  const onChipDelete = useCallback(
    (chip: string) => () => {
      onChange(value.filter((selectedValue) => !selectedValue.includes(chip)));
    },
    [onChange, value],
  );

  const renderValue = useCallback(
    (values: ISelectChipsV2Props['value']) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {parseValHelper(values).map((option) => {
            return <Chip
              key={option.value}
              label={option.label}
              deleteIcon={<Cancel onMouseDown={(e) => e.stopPropagation()} />}
              onDelete={onChipDelete(option.value)}
            />
        })}
      </Box>
    ),
    [onChipDelete],
  );

  const renderOptions = useCallback(
    () =>
      options.map((option) => (
        <MenuItem
          selected
          key={option.value}
          value={JSON.stringify(option)}
          style={getStyles(option.value, value)}
        >
          {option.label}
        </MenuItem>
      )),
    [options, value],
  );

  return (
    <Select
      id={id}
      label={label}
      value={value || []}
      formControlProps={formControlProps}
      selectProps={R.mergeLeft(
        {
          multiple: true,
          renderValue,
          MenuProps,
          input: id && label ? <OutlinedInput id={id} label={label} /> : undefined,
        },
        selectProps,
      )}
      onChange={onChange}
    >
      {renderOptions()}
    </Select>
  );
};

export default memo(SelectChipsV2);
