import client from 'configs/client';
import { IAbove } from 'modules/Dashboard/interfaces';

class AboveService {
  public async createAbove(data: Partial<IAbove>) {
    const { data: above } = await client.post(`/dashboard/aboves/create`, data);
    return above;
  }

  public async getAboves() {
    const { data: aboves } = await client.get(`/dashboard/aboves`);
    return aboves;
  }

  public async getListAboves(list_name: string) {
    const { data: aboves } = await client.get(`/dashboard/aboves/list/${list_name}`);
    return aboves;
  }
}

const aboveService = new AboveService();

export default aboveService;
