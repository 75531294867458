import client from 'configs/client';

class WorkatoService {
    public async getInfo(): Promise<any> {

        return client.get('/workato/info')
        .then((repsonse)=>{
           // console.log(repsonse);
            return repsonse;
        })
        .catch(errs=>{
            const errors=[];
            for(const key in errs) errors.push(errs[key]);
            return {status:"failed", errors}
        })
       
    }//getInfo

    public async getJobInfo(): Promise<any> {

        return client.get('/workato/jobInfo')
        .then((repsonse)=>{
           // console.log(repsonse);
            return repsonse;
        })
        .catch(errs=>{
            const errors=[];
            for(const key in errs) errors.push(errs[key]);
            return {status:"failed", errors}
        })
       
    }//getInfo

    public async syncData(): Promise<any> {
        return client.get('/workato/auth')
        .then(()=>{
            return {status:"success"}
        })
        .catch(errs=>{
            const errors=[];
            for(const key in errs) errors.push(errs[key]);
            return {status:"failed", errors}
        })
    }//getInfo
}//WorkatoService

const workatoService = new WorkatoService();

export default workatoService;