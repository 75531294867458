import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AccountStatus } from 'enums';
import strings from '../../../configs/strings';
import { ActionBtnStyles } from './../../../global-styles';

interface IUsersHeaderProps {
  accountStatus: AccountStatus;
}

const UsersHeader: React.FC<IUsersHeaderProps> = ({ accountStatus }) => {
  const navigate = useNavigate();

  const onClick = () => navigate('/user/create');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mb: 2,
      }}
    >
      <Typography variant="h3" component="h1" color="customColors.pomegranate">
        {accountStatus === AccountStatus.deactivated
          ? 'Deactivated Cafe Users'
          : 'Active Cafe Users'}
      </Typography>
      {accountStatus === AccountStatus.deactivated ? null : (
        <Button
          sx={{
            fontSize: '1rem',
            height: 'fit-content',
            ...ActionBtnStyles
          }}
          startIcon={<Add sx={{ mb: 0.2 }} />}
          variant="contained"
          onClick={onClick}
        >
          {strings.get('btn.add-user')}
        </Button>
      )}
    </Box>
  );
};

export default UsersHeader;
