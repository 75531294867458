import client from 'configs/client';
import {
  AdminRole,
  AccountType,
  UserRole,
  SupportRole,
  LowerUserRole,
  UpperUserRole,
  //AboveUnitUserRole,
  AboveCafeUserRole,
  SupportCenterUserExecutiveRoles,
  CafeOperationsDepartment,
  UserType,
} from 'enums';
import { IUser } from 'modules/Dashboard/interfaces';
import { formatForMultiSelect } from './../utils';

type Role = keyof typeof UserRole | keyof typeof LowerUserRole | keyof typeof UpperUserRole | SupportRole | AdminRole | string;
type TypeOfRole = typeof UserRole | typeof LowerUserRole | typeof UpperUserRole | typeof SupportRole | typeof AdminRole;

class UserService {
  public async fetchUser({ userId }: { userId: string }) {
    const { data } = await client.get(`/dashboard/users/${userId}`);
    // The selected units list comes from the home above unit for support center users.
    // This data object has only the home above present, if one exists, in the aboves array.
    //console.log('UserService. fetchUser(): ' + JSON.stringify(data, null, 2));
    if (
      // data.user_type === 'support_center' ||
      // data.role === AboveUnitUserRole.above_cafe_operations ||
      // data.role == AboveUnitUserRole.franchisee
      this.userHasHomeAbove(data)
    ) {
      data.unitsDisplay = data.aboves[0]?.units?.map((x) => x.name).sort();
    } else {
      data.unitsDisplay = data.units?.map((x) => x.name).sort();
    }
    // Make additional API calls to get the selected Operations Users
    // and add them to data object so the select lists show current selections.
    const opsusers = await client.get(
      `/dashboard/users/opsusers/${data.schoox_id}/${data.role}`,
    );
    data.regional_director = formatForMultiSelect(opsusers.data.regional_directors);
    data.fbl = formatForMultiSelect(opsusers.data.fbls);
    data.senior_fbl = formatForMultiSelect(opsusers.data.senior_fbls);
    return data;
  }

  public async fetchUserByRole({ role }: { role: string }) {
    const { data } = await client.get(`/dashboard/supports/role/${role}`);
    return data;
  }

  public async createUser(data: Partial<IUser>) {
    const { data: user } = await client.post(`/dashboard/users/create`, data);
    return user;
  }

  public async isEmailInUse(email: string): Promise<boolean> {
    const {data} = await client.get(`/dashboard/users/search/${email}`);
    return data;
  }

  public async updateUser({
    userId,
    data,
  }: {
    userId: string;
    data: Partial<IUser>;
  }) {
    const { data: updatedUser } = await client.put(
      `/dashboard/users/${userId}`,
      data,
    );
    return updatedUser;
  }

  public async deleteUser(userId: string) {
    const { data } = await client.delete(`/dashboard/users/${userId}`);
    return data;
  }

  public getAccountType(role: Role, is_admin: Boolean): AccountType {
    if (this.roleIncludedInList(AdminRole, role) || is_admin) {
      return AccountType.admin;
    }
    if (this.roleIncludedInList(LowerUserRole, role)) {
      return AccountType.lowerUser;
    }
    if (this.roleIncludedInList(UpperUserRole, role)) {
      return AccountType.upperUser;
    }
    if (this.roleIncludedInList(SupportRole, role)) {
      return AccountType.support;
    }

    // if (process.env.NODE_ENV === 'development') {
    //   return AccountType.admin;
    // }
  }

  private roleIncludedInList(roleType: TypeOfRole, role: string) {
    return Object.values(roleType).includes(role);
  }

  private userHasHomeAbove = (data): boolean => {
    if(data.role === 'administrator') {
        return false;
    }
    if (data.department === CafeOperationsDepartment['Cafe Operations'] && 
     !Object.values(SupportCenterUserExecutiveRoles).includes(data.role)) {
        // Not Executive and not Sr. VP....
        return true;
    } 
    else if (data.user_type === UserType.support_center) {
      return true;
    }
    else if (data.role === AboveCafeUserRole['Above Cafe Operations'] ||
      data.role === AboveCafeUserRole.Franchisee) {
        return true;
    } else {
        return false;
    }
      
  }
}

const userService = new UserService();

export default userService;
