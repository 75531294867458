import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Button, Menu, MenuItem, Link } from '@mui/material';
import { green } from '@mui/material/colors';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AccountType } from 'enums';

import { authAtom } from 'atoms';
import { authService } from 'services';
import strings from 'configs/strings';

const SidebarUser = () => {
  const navigate = useNavigate();
  const { user } = useRecoilValue(authAtom);
  const setAuth = useSetRecoilState(authAtom);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await authService.logout();
    setAuth({ isAuth: false, user: null });
    navigate('/login');
  };

  const handleTestEmailClick = async () => {
    setAnchorEl(null);
    navigate('/admin/test-email');
  };

  const handleSyncDataClick = async () => {
    setAnchorEl(null);
    navigate('/sync-data');
  };

  

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', px: 1.5, py: 3 }}>
      <Avatar sx={{ background: green[600], fontSize: '1.1rem' }}>
        {user.firstname[0].toUpperCase()}
        {user.lastname[0].toUpperCase()}
      </Avatar>
      <Box sx={{ ml: 2, fontSize: '1rem', maxWidth: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            fontWeight: 'bold',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user.firstname} {user.lastname}
        </Box>
        <Box
          sx={{
            fontWeight: 500,
            mt: 0.3,
            fontSize: '0.8rem',
            color: 'black',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user.email}
        </Box>
        <Box sx={{ fontSize: '0.7rem', mt: 0.2, color: 'black', fontWeight: 500 }}>
          {user.accountType === AccountType.upperUser ||
          user.accountType === AccountType.lowerUser
            ? 'user'
            : user.accountType}
        </Box>
      </Box>
      <Box sx={{ marginLeft: 'auto', cursor: 'pointer' }}>
        <Button
          variant="text"
          sx={{
            minWidth: 'auto',
            padding: '4px',
            borderRadius: '8px',
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon
            sx={{ color: 'customColors.sea', '&:hover': { fill: '#1976d2' } }}
          />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '.MuiMenu-list': {
              backgroundColor: 'customColors.pomegranate',
              padding: 0,
              '&:hover': {
                backgroundColor: 'customColors.punch',
              },
            },
          }}
        >
          <MenuItem
            sx={{
              color: 'white',
              paddingY: '10px',
              '&:hover': {
                backgroundColor: 'transpat',
              },
            }}
            component={Link}
            href="/update-profile"
          >
            {strings.get('label.update-user')}
          </MenuItem>
          {/* <MenuItem>Edit</MenuItem> */}
          {!user.is_admin ? null : (
            <MenuItem
              sx={{
                color: 'white',
                paddingY: '10px',
                '&:hover': {
                  backgroundColor: 'transpat',
                },
              }}
              onClick={handleSyncDataClick}
            >
              {strings.get('menu.admin.sync-data')}
            </MenuItem>
          )}
           {!user.is_admin ? null : (
            <MenuItem
              sx={{
                color: 'white',
                paddingY: '10px',
                '&:hover': {
                  backgroundColor: 'transpat',
                },
              }}
              onClick={handleTestEmailClick}
            >
              {strings.get('menu.admin.test-email')}
            </MenuItem>
          )}
          <MenuItem
            sx={{
              color: 'white',
              paddingY: '10px',
              '&:hover': {
                backgroundColor: 'transpat',
              },
            }}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default SidebarUser;
