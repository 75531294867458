import { Grid } from '@mui/material';
import { useEffect } from 'react';

import { FormCheckbox } from 'components';
//import { UpperUserRole, UserRole } from 'enums';
import { UseUserFormReturn } from '../hooks/useUserForm';
import FormSection from '../layout/FormSection';
//import { ROLE_EMPLOYMENT_STATUS_MAP } from '../maps';

import strings from "../../../configs/strings";

interface IUserDesignatedOperatorSectionProps {
  control: UseUserFormReturn['control'];
  setValue: UseUserFormReturn['setValue'];
  watch: UseUserFormReturn['watch'];
}

const UserDesignatedOperatorSection: React.FC<IUserDesignatedOperatorSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  //const { user } = useRecoilValue(authAtom);
  const role = watch('role');
  //const isAdmin = user?.accountType === AccountType.admin 
  //const isUperRole = (Object.values(UpperUserRole).includes(role));

  const is_designated_operator_value = watch('is_designated_operator');
  console.log("========== is_designated_operator: " + is_designated_operator_value)
   



  useEffect(() => {
    //setValue('employment_status', ROLE_EMPLOYMENT_STATUS_MAP[role]);
    
  }, [role, setValue]);



  return (
    
    <FormSection title="Designated Operator">
        <Grid item xs={6}>
          <FormCheckbox
            control={control}
            name="is_designated_operator"
            label={strings.get('label.is-designated-operator')}
          />
        </Grid>
    </FormSection>
  );
};

export default UserDesignatedOperatorSection;
