import { FormControl, OutlinedInput, InputLabel } from '@mui/material';
import { useCallback, memo } from 'react';

import { useDebounceFn } from 'hooks';
import { ISearchFilter } from '../interfaces';
import strings from '../../../configs/strings';
import { dropDownSearchBarStyles } from './../../../global-styles';

interface ISearchInputProps {
  value: ISearchFilter['search'];
  setValue: React.Dispatch<React.SetStateAction<ISearchFilter['search']>>
  onChange: ({ search }: Partial<ISearchFilter>) => any;
}

const SearchInput: React.FC<ISearchInputProps> = ({ value, setValue, onChange }) => {
  const debouncedChange = useDebounceFn(onChange);

  const onInputChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setValue(target.value);
      debouncedChange({ search: target.value });
    },
    [debouncedChange, setValue],
  );

  return (
    <FormControl fullWidth sx={dropDownSearchBarStyles}>
      <InputLabel>{strings.get('label.search')}</InputLabel>
      <OutlinedInput
        autoComplete="off"
        id="search-input"
        label={strings.get('label.search')}
        placeholder="First Name, Last Name or Email"
        value={value}
        onChange={onInputChange}
      />
    </FormControl>
  );
};

export default memo(SearchInput);
