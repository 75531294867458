import { useMutation } from '@tanstack/react-query';
import { IUser } from 'modules/Dashboard/interfaces';

import { userService } from 'services';

const createUser = async (data: Partial<IUser>) => {
  return await userService.createUser(data);
};

const useCreateUser = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation({
    mutationFn: (data: Partial<IUser>) => createUser(data),
  });
  return { mutate, isLoading, isError, isSuccess, error };
};

export default useCreateUser;
