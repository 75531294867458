import { Box, Button } from '@mui/material';

import { CancelBtnStyles, ActionBtnStyles } from './../../../global-styles';

interface IFormFooter {
  submitLabel: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  isWaiting?: boolean;
}

const FormFooter: React.FC<IFormFooter> = ({ submitLabel, onCancel, onSubmit, isWaiting }) => (
  <Box
    sx={{
      width: '100%',
      mt: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 2,
      '& > button': {
        fontSize: '0.9rem',
      },
    }}
  >
    {/* hide cancel button if handler not specified */}
    {!onCancel ? "" :
    <Button
      variant="contained"
      onClick={onCancel}
      sx={CancelBtnStyles}
    >
      Cancel
    </Button>
    }
    <Button
      variant="contained"
      type="submit"
      disabled={isWaiting}
      onSubmit={onSubmit}
      sx={ActionBtnStyles}
    >
      {submitLabel}
    </Button>
  </Box>
);

export default FormFooter;
