import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, FormHeader } from '../../Forms/layout';
import { Message } from 'components';
import { authService } from 'services';
import { FormRow } from '../components';
import { useRecoilValue } from 'recoil';
import { authAtom } from 'atoms';
import strings from '../../../configs/strings';
import { ActionBtnStyles } from '../../../global-styles';

import { updateProfileFormInitialValues } from './../../Dashboard/constants';
import useUpdateUserProfileForm from '../../Forms/hooks/useUpdateUserProfileForm';
import { IUserProfileFormFields } from './../../../modules/Forms/interfaces';
import UserContactSection from '../../Forms/sections/UserContactSection';
import UserPasswordSection from '../../Forms/sections/UserPasswordSection';

import { objHasValues } from '../../../utils/objHasValues';

const MessageStyles = { fontSize: '20px' };


const UpdateUserForm = () => {
  const { user } = useRecoilValue(authAtom);
  const userId = user.uuid;
  const {
    isEdit,
    control,
    errors,
    dirtyFields,
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = useUpdateUserProfileForm({ userId });

  const navigate = useNavigate();

  const [err, setErr] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (objHasValues(user)) {
      const formValues: IUserProfileFormFields = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        newPassword: '',
        confirmPassword: '',
      };
      reset(formValues);
    }
  }, [reset, user]);

  const onSubmit = async (data: IUserProfileFormFields) => {
    setErr('');
    setSuccess('');
    // console.log(data);

    try {
      const {
        newPassword,
        confirmPassword,
        firstname,
        lastname,
        email,
      } = data;

      const passwordObj = { password: '' };
      if (newPassword && confirmPassword && newPassword === confirmPassword) {
        // update password
        passwordObj.password = newPassword;
      }

      await authService.selfUpdate(userId, firstname, lastname, email, newPassword);

      reset({ ...updateProfileFormInitialValues, ...data });
      setSuccess(strings.get('label.user-updated'));
    } catch (error) {
      setSuccess('');
      setErr(error.message);
    }
  };

  // if status is successful, redirect after 2 seconds
  useEffect(() => {
    if (!success) return;

    const timeId = setTimeout(() => {
      // After 2 seconds clear success message
      setSuccess('');
    }, 2000)

    return () => {
      clearTimeout(timeId)
    }
  }, [success]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <FormHeader title={strings.get('label.update-user')} />
      <UserContactSection
        control={control}
        isEdit={isEdit}
        dirtyFields={dirtyFields}
        setError={setError}
        reset={reset}
        clearErrors={clearErrors}
      />
      <UserPasswordSection control={control} />

      {err && <Message variant="error" sx={MessageStyles} >{err}</Message>}
      {success && !objHasValues(errors) && !err && (
        <Message variant="success" sx={MessageStyles} >{success}</Message>
      )}
      <FormRow>
        <span />
        <Button type="submit" sx={ActionBtnStyles}>
          {strings.get('btn.submit')}
        </Button>
      </FormRow>
    </Container>
  );
};

export default UpdateUserForm;
