import { PropsWithChildren, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

interface IActionCellProps extends PropsWithChildren<GridRenderCellParams> {
  onClick: (uuid: string) => void;
  text: string;
}

const ActionCell: React.FC<IActionCellProps> = ({
  children,
  text,
  row,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <span onClick={() => setIsOpen(true)}>
        {children || text}
      </span>
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmText={text}
        bodyText={`This will ${text.toLowerCase()} ${row.firstname} ${row.lastname}`}
        onSuccess={() => onClick(row.uuid)}
      />
    </>
  );
};

export default ActionCell;
