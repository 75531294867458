import React, {useEffect} from 'react';
import { Paper, Typography, Button, Box, Grid } from '@mui/material';
//import Toast, { ToastType } from 'components/Toast/Toast';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {  useState } from 'react';

import { ActionBtnStyles } from './../../../global-styles';
//import workato from './../../../configs/workato';

import { workatoService } from 'services';



const SyncData: React.FC = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [startedAt, setStartedAt] = useState('');
  const [completedAt, setCompletedAt] = useState('');

  const [disableRefreshBtn, setDisableRefreshBtn] = useState(false);
  const [disableSyncBtn, setDisableSyncBtn] = useState(false);
  //const [disableSyncConfirmBtn, setDisableSyncConfirmBtn] = useState(false);

  const getSyncData = async () => {
   // console.log(workato.getLastSynced());
    await workatoService.syncData()
   //console.log("results:", results);
   setDisableSyncBtn(true);
   await getLastCalled();
   setIsOpen(false);

  }//getSyncData

   const getLastCalled = async () =>{
      const results = await workatoService.getJobInfo();
  
     if(results.items[0].completed_at == null){
      setCompletedAt("Running");
      setDisableRefreshBtn(true);
      setDisableSyncBtn(true);
     }else{
      setCompletedAt(new Date(results.items[0].completed_at).toString() );
      setDisableRefreshBtn(false);
      setDisableSyncBtn(false);
     }

     setStartedAt(new Date(results.items[0].started_at).toString());

     return;
  
  }//getLastCalled


  useEffect(() => {
    // call api or anything
    //console.log("loaded");
    getLastCalled();
 });

  

  return (
    <>
  <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, borderColor: 'customColors.sky' }}>
    <Typography
    variant="h3"
    fontWeight="bold"
    align="center"
    sx={{ mb: 6, fontSize: 36 }}
    color="customColors.pomegranate"
  >
    Sync Data
  </Typography>

    <Typography variant="body1" fontSize={16} marginTop={1.5}>
    TSC UP automatically synchronizes data from The Blend on a scheduled basis, every three hours.  The button below is used to manually start the sync process on demand outside of its normal schedule.  Note this does not replace the automatic sync process which will still run at its normally scheduled time.
    </Typography>

    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, borderColor: 'customColors.pomegranate' }}>
    <Typography
    variant="h6"
    fontWeight="bold"
    align="center"
    sx={{ mb: 2, fontSize: 18 }}
    color="customColors.pomegranate"
  >
    Last time Synced
  </Typography>
        <Grid container spacing={2}>
  <Grid item xs={10}>
  <div><strong>Started At:</strong> {startedAt}</div>
        <div><strong>Completed At:</strong> {completedAt}</div>
  </Grid>
  <Grid item xs={2} textAlign='right'>
  <Button
          sx={{
            fontSize: '1rem',
            height: 'fit-content',
            ...ActionBtnStyles
          }}
          disabled={disableRefreshBtn}
          variant="contained"
          onClick={() =>getLastCalled()}
        >
          Refresh
        </Button>
  </Grid>
  </Grid>
    </Paper>
    <Box textAlign='center'>
    <Button
          sx={{
            fontSize: '1rem',
            height: 'fit-content',
            ...ActionBtnStyles
          }}
          variant="contained"
          disabled={disableSyncBtn}
          onClick={() =>setIsOpen(true)}
        >
          Sync
        </Button>
        </Box>
  </Paper>

  <ConfirmationModal
  isOpen={isOpen}
  setIsOpen={setIsOpen}
  confirmText={'Sync Data'}
  bodyText={`Syncing Data will NOT stop the next scheduled sync. This will take a few minutes to complete.`}
  onSuccess={getSyncData}
 />
 
  </>
  );
}

export default SyncData;