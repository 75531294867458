// const SelectAutocompleteStyles = {
//   minWidth: '205px',
//     '.MuiInputLabel-formControl, .MuiSelect-iconOutlined, .MuiInputBase-formControl': {
//         color: 'customColors.sea',
//         '&.MuiInputLabel-shrink': {
//           color: 'customColors.sky',
//         }
//       },
//       '.MuiInputBase-root.MuiInputBase-formControl': {
//         '.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline': {
//           borderColor: 'customColors.sky',
//         },
//         '&:hover': {
//           borderColor: 'customColors.turquoise',
//         },
//         '&:focus': {
//           color:  'customColors.sky',
//         },
//       },
// }

// export default SelectAutocompleteStyles;

export const SelectAutocompleteFormControlStyles = {
  minWidth: '205px',
};

export const SelectAutocompleteStyles = {
  '& .autocomplete-input': {
    '.autocomplete-input-label': {
      color: 'customColors.sea',
      '&.MuiInputLabel-shrink': {
        color: 'customColors.sky',
      },
    },
    '.MuiInputBase-root.MuiInputBase-formControl': {
      '.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline':
        {
          borderColor: 'customColors.sky',
          '&:hover': {
            borderColor: 'customColors.turquoise',
          },
          '&:focus': {
            color: 'customColors.sky',
          },
        },
      '.MuiAutocomplete-popupIndicator': {
        '&, &Open': {
          color: 'customColors.sea',
          backgroundColor: 'unset',

          '.MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },
  },
};

export const dropDownMenuStyles = {
  '&.MuiAutocomplete-option': {
  '&.Mui-selected:hover.Mui-focused, &:hover.Mui-focused, &[aria-selected="true"].Mui-focused': {
    backgroundColor: 'customColors.turquoise',
  },
  '&:target, &[aria-selected=true], &.Mui-focusVisible': {
    backgroundColor: 'customColors.sky',
  }, 
}
};