import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { IUnit } from '../interfaces';
import { FormSelect, FormCheckbox } from 'components';
import { EmploymentStatus, SupportRole, DepartmentConstants } from 'enums';
import { EMPLOYMENT_STATUS_DISPLAY_MAP } from 'maps';
import { getOptions } from 'utils';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import FormSection from '../layout/FormSection';
import { SUPPORT_ROLE_EMPLOYMENT_STATUS_MAP } from '../maps';
import useGetListAboveUnits from '../queries/useGetListAboveUnits';
import useGetListUnits from '../queries/useGetListUnits';
import useGetListJobs from '../queries/useGetListJobs';
import { IAbove, IJob } from 'modules/Dashboard/interfaces';
import SupportOperationsUsersSection from './SupportOperationsUsersSection';
import SupportCafeDetailsSection from './SupportCafeDetailsSection';
import strings from '../../../configs/strings';

interface ISupportOrganizationSectionProps {
  control: UseSupportFormReturn['control'];
  setValue: UseSupportFormReturn['setValue'];
  watch: UseSupportFormReturn['watch'];
  getValues: UseSupportFormReturn['getValues'];
  reset: UseSupportFormReturn['reset'];
  isEdit: UseSupportFormReturn['isEdit'];
}

//const CAFE_OPERATIONS_SCHOOX_ID = 735836;
//const CAFE_OPERATIONS_NAME = 'Cafe Operations';

const SupportOrganizationSection: React.FC<ISupportOrganizationSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  const role = watch('role');
  const department = watch('department');
  const isHeadOfDepartment = watch('is_head_of_department');
  const isHeadRole =
    role === SupportRole.executive || role === SupportRole.senior_vice_president;

  const { data: aboveUnits } = useGetListAboveUnits({ list_name: 'department' });
  const { data: units } = useGetListUnits({ list_name: 'team' });
  const { data: jobs } = useGetListJobs({ list_name: 'support_center' });

  const roleOptions = useMemo(
    //() => getOptions(Object.keys(SupportRole), SUPPORT_LEARNER_ROLE_DISPLAY_MAP),
    //[],
    () =>
      jobs?.map((job: IJob) => ({
        value: job.name,
        label: job.name,
      })) || [],
    [jobs],
  );

  const employmentStatusOptions = useMemo(
    () => getOptions(Object.keys(EmploymentStatus), EMPLOYMENT_STATUS_DISPLAY_MAP),
    [],
  );

  const departmentOptions = useMemo(
    () =>
      aboveUnits?.map((above: IAbove) => ({
        value: above.name,
        label: above.name,
      })) || [],
    [aboveUnits],
  );

  const cafeOperationsDepartment = aboveUnits?.find(
    (above: IAbove) => above.name === DepartmentConstants.cafe_operations,
  );
  /*
  const cafeOperationsFieldTeam = units?.find(
    (unit: IUnit) => unit.name === 'Operations - Field',
  );
  */

  const teamsOptions = useMemo(
    () =>
      units
        ?.filter((unit: IUnit) => unit.list_department === department)
        .map((unit: IUnit) => ({
          value: unit.name,
          label: unit.name,
        })) || [],
    [units, department],
  );


  useEffect(() => {
    setValue('employment_status', SUPPORT_ROLE_EMPLOYMENT_STATUS_MAP[role]);

    if (
      role === SupportRole.executive ||
      role === SupportRole.senior_vice_president
    ) {
      setValue('is_head_of_department', true);
    } else {
      setValue('is_head_of_department', false);
    }
  }, [role, setValue]);

  return (
    <>
      <FormSection title="Organization">
        <Grid item marginTop={2} xs={6}>
          <FormSelect
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            options={roleOptions}
            control={control}
            id="role"
            name="role"
            label={strings.get('label.learner-role')}
          />
        </Grid>
        <Grid item marginTop={2} xs={6}>
          <FormSelect
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            options={employmentStatusOptions}
            control={control}
            id="employment_status"
            name="employment_status"
            label={strings.get('label.employment-status')}
          />  <small><em>Hourly employees will be required to take their courses at the cafe to avoid off the clock work.</em></small>
      
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            options={departmentOptions}
            control={control}
            id="department"
            name="department"
            label={strings.get('label.department')}
          />
        </Grid>
        <Grid item xs={6}>
          {isHeadRole && (
            <FormCheckbox
              control={control}
              name="is_head_of_department"
              label={strings.get('label.head-department')}
            />
          )}
        </Grid>
        {!isHeadOfDepartment && (
          <Grid item xs={6}>
            <FormSelect
              formControlProps={{
                fullWidth: true,
                required: true,
                //disabled: !team,
              }}
              options={teamsOptions}
              control={control}
              id="team"
              name="team"
              label={strings.get('label.team')}
            />
          </Grid>
        )}
      </FormSection>
      {department === cafeOperationsDepartment?.name && role && !isHeadRole && (
        <>
          <SupportOperationsUsersSection
            control={control}
            setValue={setValue}
            watch={watch}
          />
          <SupportCafeDetailsSection
            control={control}
            setValue={setValue}
            watch={watch}
          />
        </>
      )}
    </>
  );
};;

export default SupportOrganizationSection;
