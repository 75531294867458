import client from 'configs/client';

class CafeService {
  public async getCafeNumbers() {
    const { data } = await client.get('/dashboard/cafes/numbers');
    return data;
  }

  public async getStoreCafes() {
    const { data } = await client.get(`/dashboard/cafes/stores`);

    return data;
  }
}

const cafeService = new CafeService();

export default cafeService;
