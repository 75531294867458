import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { AccountStatus } from 'enums';
import { dashboardService } from 'services';
import { useRowCount } from '../hooks';
import { IPagination, ISortModel, IUsersFilter } from '../interfaces';
import { DASHBOARD_USERS_QUERY_KEY, useGetUsers } from '../queries';

interface IUseUsers extends IPagination {
  account_status: AccountStatus;
  filter: Partial<IUsersFilter>;
  sortModel: ISortModel;
}

const useUsers = ({
  account_status,
  filter,
  sortModel,
  page,
  pageSize,
}: IUseUsers) => {
  const queryClient = useQueryClient();
  const { data, totalItems, loading } = useGetUsers({
    account_status,
    filter,
    page,
    pageSize,
    sortModel,
  });
  const { rowCount } = useRowCount({ totalItems });

  const onSuccess = useCallback(() =>
    queryClient.invalidateQueries({ queryKey: [DASHBOARD_USERS_QUERY_KEY] }), [queryClient]);

  const deactivateMutation = useMutation({ mutationFn: dashboardService.deactivateUser });

  const activateMutation = useMutation({ mutationFn: dashboardService.activateUser });

  const reFetch = useCallback(() => onSuccess(), [onSuccess]);

  return {
    data,
    rowCount,
    loading,
    deactivateMutation,
    activateMutation,
    reFetch,
    onSuccess
  };
};

export default useUsers;
