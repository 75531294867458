import { Drawer, Divider } from '@mui/material';

import SidebarLogo from './SidebarLogo';
import SidebarUser from './SidebarUser';
import SidebarItems from './SidebarItems';

const version = process.env.REACT_APP_VERSION;

const drawerWidth = 270;

const Sidebar = () => (
  <Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', borderColor: 'customColors.sky' },
    }}
  >
    <SidebarLogo />
    <SidebarUser />
    <Divider sx={{ borderColor: 'customColors.sky'}}/>
    <SidebarItems />
    <div style={{color: "lightgray", position: "absolute", bottom: "0px", left: "100px"}} >{version}</div>
  </Drawer>
);

export default Sidebar;
