import client from 'configs/client';
import { AccountStatus, UserRole, SupportRole } from 'enums';
import { IResponseBodyWithPagination } from 'interfaces';
//import { omitEmpty } from 'utils';
import {
  IUsersFilter,
  ISupportsFilter,
  IUser,
  IPagination,
  ISortModel,
} from 'modules/Dashboard/interfaces';

interface IGetQueryParams {
  account_status: AccountStatus;
}

interface IGetUsersQueryParams extends IGetQueryParams, IUsersFilter, IPagination {
  role?: UserRole;
  sortModel: ISortModel;
}

interface IGetSupportsQueryParams extends IGetQueryParams, ISupportsFilter {
  role?: SupportRole;
  sortModel: ISortModel;
}

class DashboardService {
  public fetchUsers = async ({
    sortModel: { field, order },
    ...rest
  }: IGetUsersQueryParams) => {
    const params = { ...rest, field, order };
    const query = this.convertToQueryParams(params);
    /*
    console.log(
      '======= dashboardService: fetchUsers: query: ' + JSON.stringify(query),
    );
    */
    return await client.get<IResponseBodyWithPagination<IUser[]>>(
      `/dashboard/users?${query}`,
    );
  };

  public fetchSupports = async ({
    sortModel: { field, order },
    ...rest
  }: IGetSupportsQueryParams) => {
    const params = { ...rest, field, order };
    const query = this.convertToQueryParams(params);
    return await client.get(`/dashboard/supports?${query}`);
  };

  public activateUser = async (userId: string) => {
    return await client.put(`/dashboard/users/${userId}/activate`);
  };

  public deactivateUser = async (userId: string) => {
    return await client.put(`/dashboard/users/${userId}/deactivate`);
  };

  public activateSupport = async (userId: string) => {
    return await client.put(`/dashboard/supports/${userId}/activate`);
  };

  public deactivateSupport = async (userId: string) => {
    return await client.put(`/dashboard/supports/${userId}/deactivate`);
  };

  /*
  private convertToQueryParams = (params: Record<string, any>) => {
    return Object.entries(omitEmpty(params))
      .map((kv) => {
        if (Array.isArray(kv[1])) kv[0] += '[]';
        return kv.map(encodeURIComponent).join('=');
      })
      .join('&');
  };
  */
  // Changing this function because the backend is expecting array arguments to be passed like
  // cafe_numbers[]=41&cafe_numbers[]=42 
  // The old function returned array arguments like
  // cafe_numbers[]=43,42 <-- this is NOT what the backend is expecting
  //
  private convertToQueryParams(params: Record<string, any>): String{
    let queryStr: string = "";
    for(const k in params){
        const v=params[k];
        if(Array.isArray(v)){
            // eslint complains about using queryStr in the loop
            // eslint-disable-next-line
            v.forEach(val=>{
                if(queryStr !== "") queryStr+="&";
                queryStr += encodeURIComponent(k + "[]") + "=" + encodeURIComponent(val);
            })
        }else if(v !== undefined && v !== "" && v !== null) {
            if(queryStr !== "") queryStr+="&";
            queryStr += encodeURIComponent(k) + "=" + encodeURIComponent(v);
        }
    }
    return queryStr;
}
}

const dashboardService = new DashboardService();

export default dashboardService;
