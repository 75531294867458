import React from 'react';
import { useController, Control } from 'react-hook-form';
import SelectAutocomplete, { IAutocompleteProps } from './SelectAutocomplete';

interface IAutocompleteAdditionalProps {
  name: string;
  control: Control<any>;
}
const FormSelectAutocomplete = <T extends {}>({
  name,
  control,
  id,
  label,
  options,
  formControlProps = {},
  ...rest
}: Omit<IAutocompleteProps<T>, 'value' | 'onChange'>
& IAutocompleteAdditionalProps) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <SelectAutocomplete
      {...(delete field.ref && field)} 
      id={id}
      label={label}
      options={options}
      onChange={(__, value) => {
        field.onChange(value.sort())
      }}
      formControlProps={{
        ...formControlProps,
        error: fieldState.error,
      }}
      {...rest}
    />
  );
};

export default FormSelectAutocomplete;
