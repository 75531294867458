import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

const lowerCase = (val: string) => val.toLowerCase();

function validationMethods() {
  return this.min(8, 'At least 8 characters need to be provided!')
    .max(64, 'At less than 64 characters need to be provided!')
    .matches(/^\S*$/, 'No whitespace allowed!')
    .matches(/^(?=.*[a-z])/, 'Must Contain One Lowercase Character!')
    .matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase Character!')
    .matches(
      /^(?!.*(.)\1\1.*).*$/,
      'No more than three identical characters in a row!',
    )
    .test(
      'is-first-name-in-password',
      'Your firstname are not allowed in your password!',
      function (val) {
        const lowercaseProvidedPassword = lowerCase(val);
        const firstName = lowerCase(this.parent.firstname);
        return !lowercaseProvidedPassword.includes(firstName);
      },
    )
    .test(
      'is-last-name-in-password',
      'Your lastname are not allowed in your password!',
      function (val) {
        const lowercaseProvidedPassword = lowerCase(val);
        const lastName = lowerCase(this.parent.lastname);
        return !lowercaseProvidedPassword.includes(lastName);
      },
    )
    .test(
      'is-logout-in-password',
      "The term 'logout' cannot be in your password!",
      function (val) {
        const lowercaseProvidedPassword = lowerCase(val);
        return !lowercaseProvidedPassword.includes('logout');
      },
    )
    .test(
      'is-academy-name-in-password',
      'The academy name cannot be in your password!',
      function (val) {
        const lowercaseProvidedPassword = lowerCase(val);
        return !lowercaseProvidedPassword.includes('theblend');
      },
    );
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'passwordValidator',
  validationMethods,
);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    passwordValidator(): StringSchema<TType, TContext>;
  }
};


