interface IEmailCellProps {
  email: string;
}

const EmailCell: React.FC<IEmailCellProps> = ({ email }) => (
  <a href={`mailto:${email}`}>
    {email}
  </a>
);

export default EmailCell;
