import * as R from 'ramda';
import { List, ListItemButton, ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from 'atoms';
import { routes } from 'configs/routes/constants';
import { ICurrentUser } from 'modules/Auth/interfaces';

const SidebarItems = () => {
  const { user } = useRecoilValue(authAtom);

  const renderItems = () =>
    routes
      .filter((route) => {
        const rolesIncludes = (roleType: ICurrentUser["accountType"] | ICurrentUser["role"]) => R.includes(roleType, route.roles);
        return (rolesIncludes(user.accountType) || rolesIncludes(user.role)) && !route.hidden;
      })
      .map(({ label, path }, index) => (
        <ListItem key={index} disablePadding>
          <NavLink
            to={path}
            end={path === '/'}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            {({ isActive }) => (
              <>
                <ListItemButton
                  sx={{
                    alignItems: 'center',
                    p: 1.5,
                    transition: 'none',
                    WebkitTransition: 'none',
                    color: 'black',
                    backgroundColor: isActive ? 'customColors.sky': 'none',
                    '&:hover': {
                      backgroundColor: 'customColors.turquoise',
                    },
                    '&:active': {
                      backgroundColor: 'customColors.sea',
                    }
                  }}
                >
                  <ListItemText
                    sx={{
                      '& span': {
                        color: 'black',
                        fontSize: '1rem',
                        fontWeight: isActive ? 'bold' : 500,
                        ml: 1,
                      },
                    }}
                    primary={label}
                  />
                </ListItemButton>
              </>
            )}
          </NavLink>
        </ListItem>
      ));

  return <List disablePadding>{renderItems()}</List>;
};

export default SidebarItems;
