import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Typography, Stack } from '@mui/material';
import { DataGrid, gridDateComparator, GridComparatorFn, gridNumberComparator } from '@mui/x-data-grid';
import Link from '@mui/material/Link';

import documentService from '../../../services/documentService';


const customGridDateComparator: GridComparatorFn = (v1, v2, param1, param2) => gridDateComparator(v2.lastModifiedISO, v1.lastModifiedISO, param1, param2);

const customFileSizeComparator: GridComparatorFn = (v1, v2, param1, param2) => gridNumberComparator(v1.unformatted, v2.unformatted, param1, param2);

const columns = [
  {
    field: 'fileName',
    headerName: 'File',
    flex: 3,
    renderCell: (params) => {
      const { fileBuffer, fileName } = params.row;
      return (
        <Link
          href={fileBuffer}
          component="a"
          underline="hover"
          color="inherit"
          target="_self"
          download={fileName}
        >
          {fileName}
        </Link>
      );
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    flex: 1,
    sortComparator: customFileSizeComparator,
    renderCell: (params) => {
      const { formatted } = params.formattedValue;
      return formatted;
    },
  },
  {
    field: 'fileDate',
    headerName: 'Last Modified',
    flex: 1,
    sortComparator: customGridDateComparator,
    renderCell: (params) => {
      const { lastModified } = params.formattedValue;
      return lastModified;
    },
  },
];

const NoFiles = () => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    No files
  </Stack>
);

const CustomErrorOverlay = ({ err }: { err: string }) => (
  <Alert severity="error" sx={{ fontSize: '1.4rem' }}>
    {' '}
    <AlertTitle sx={{ fontSize: '1.5rem' }}>Error</AlertTitle> {err}
  </Alert>
);


const DownloadDocumentPage: React.FC = () => {
  const [files, setFiles] = useState([]);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);

  // set initial file list state
  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const { error, files } = await documentService.listDocumentsForUser();
        if (error) {
          throw new Error(error);
        } else {
          // using the file name, retrieve file data (the buffer) from the db
          const fileList = await Promise.all(
            files.map(async ({ name, lastModified, lastModifiedISO, size: sizeFormatted , sizeUnformatted }, index) => {
              // get all file buffers from db
              const fileBuff = await documentService.getFileForDownload(name);
              // assign buffer to blob format so that it can be added to the href
              const blob = new Blob([fileBuff]);
              // create link for href
              const objectURL = URL.createObjectURL(blob);
              return {
                id: `${name}-${index}`,
                fileName: name,
                fileBuffer: objectURL,
                fileDate: {
                  lastModified,
                  lastModifiedISO
                },
                size: {
                  formatted: sizeFormatted,
                  unformatted: sizeUnformatted,
                },
              };
            }),
          );

          setFiles(fileList);
        }
      } catch (error) {
        setErr(error.message || 'An Error occurred! Please try again later.');
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Typography
        variant="h3"
        component="h1"
        color="customColors.pomegranate"
        sx={{ mb: 1 }}
      >
        Documents
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="customColors.metallicSeaweed"
        sx={{ mb: 3 }}
      >
        Click filename to download
      </Typography>
      {err ? (
        <CustomErrorOverlay err={err} />
      ) : (
        <DataGrid
          components={{
            NoRowsOverlay: NoFiles,
          }}
          rows={files}
          columns={columns}
          getRowHeight={() => 'auto'}
          autoHeight
          loading={loading}
          density="compact"
          hideFooter={files?.length <= 100}
          sx={{
            '.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell':
              {
                border: 'none',
              },
            '.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader, .MuiDataGrid-cell':
              {
                '&:focus, &:focus-within': {
                  outline: 'unset',
                },
              },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'fileDate', sort: 'asc' }],
            },
          }}
        />
      )}
    </>
  );
};

export default DownloadDocumentPage;
