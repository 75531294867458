import { useQuery } from '@tanstack/react-query';

import unitService from 'services/unitService';
import { IUnit } from 'modules/Dashboard/interfaces';

export interface IUseGetUnitsProps {
  list_name: string;
}

const getUnits = async ({ list_name }: IUseGetUnitsProps): Promise<IUnit[]> => {
  return await unitService.getListUnits(list_name);
};

const useGetListUnits = ({ list_name }: IUseGetUnitsProps) => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['get_list_units'],
    () => getUnits({ list_name }),
    { placeholderData: [] },
  );
  return { data, error, loading: isLoading || isFetching };
};

export const DASHBOARD_UNITS_QUERY_KEY = 'units';

export default useGetListUnits;
