import React from 'react';
import { Alert, Snackbar } from '@mui/material';

export enum ToastType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

interface IToast {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  type: ToastType;
  autoHideDuration?: number;
}

const Toast: React.FC<IToast> = ({
  isOpen,
  setIsOpen,
  message,
  type,
  autoHideDuration = 3000,
}: IToast) => {
  const onClose = () => setIsOpen(false);
  return (
    <Snackbar open={isOpen} onClose={onClose} autoHideDuration={autoHideDuration}>
      <Alert
        variant="filled"
        severity={type}
        onClose={onClose}
        sx={{ fontSize: 14, whiteSpace: 'pre-line'}}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
