import { Grid } from '@mui/material';

import { FormTextField } from 'components';
import { UseUserFormReturn } from '../hooks/useUserForm';
import { UseUpdateUserProfileFormReturn } from '../hooks/useUpdateUserProfileForm';
import FormSection from '../layout/FormSection';
import strings from '../../../configs/strings';
import { userService } from 'services';

interface IUserContactSectionProps {
  control: UseUserFormReturn['control'] | UseUpdateUserProfileFormReturn['control'];
  isEdit: UseUserFormReturn['isEdit'] | UseUpdateUserProfileFormReturn['isEdit'];
  dirtyFields: UseUserFormReturn['dirtyFields'] | UseUpdateUserProfileFormReturn['dirtyFields'];
  setError: UseUserFormReturn['setError'] | UseUpdateUserProfileFormReturn['setError'];
  reset: UseUserFormReturn['reset'] | UseUpdateUserProfileFormReturn['reset'];
  clearErrors: UseUserFormReturn['clearErrors'] | UseUpdateUserProfileFormReturn['clearErrors'];
}

const UserContactSection: React.FC<IUserContactSectionProps> = ({ control, isEdit, dirtyFields, setError ,clearErrors}) => {
  const emailBlurHandler = async (e) => {
    // checking if email is in use here
    console.log("email is dirty?",dirtyFields.email)
    //only validate if we're adding, or if we're updating and the email field is different than at load
    if(!isEdit || dirtyFields.email ){
      const target = e.target as HTMLButtonElement;
      const emailInUse = await userService.isEmailInUse(target.value);
      console.log('checking for existing email...', target.value);
      if (emailInUse) {
        console.log('email is in use...show error');
        setError('email', { type: 'onBlur', message: strings.get("errors.email-in-use") })
      }else{
        console.log('email is NOT in use...clear error');
        clearErrors("email")
      }
    }else{      
      console.log("didn't need to validate...make sure to clear email error");
      clearErrors("email")
    }
  }

  
  return (
    <FormSection title="Contact">
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          control={control}
          id="firstname"
          name="firstname"
          label={strings.get('label.first-name')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          control={control}
          id="lastname"
          name="lastname"
          label={strings.get('label.last-name')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          type="email"
          control={control}
          id="email"
          name="email"
          label={strings.get('label.email')}
          onBlur={emailBlurHandler}
        />
      </Grid>
    </FormSection>
  );
};

export default UserContactSection;
