import { Box, Grid } from '@mui/material';

import { FormSelect, FormTextField, FormCheckbox } from 'components';
import { States } from 'enums';
import { useMemo } from 'react';
import { getOptions } from 'utils';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import FormSection from '../layout/FormSection';
import strings from '../../../configs/strings';

interface ISupportUserContactSectionProps {
  control: UseSupportFormReturn['control'];
}

const SupportContactSection: React.FC<ISupportUserContactSectionProps> = ({
  control,
}) => {
  const statesOptions = useMemo(() => getOptions(Object.keys(States), States), []);

  return (
    <FormSection title="Contact">
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          control={control}
          id="firstname"
          name="firstname"
          label={strings.get('label.first-name')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          control={control}
          id="lastname"
          name="lastname"
          label={strings.get('label.last-name')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          required
          fullWidth
          type="email"
          control={control}
          id="email"
          name="email"
          label={strings.get('label.email')}
        />
        <Box sx={{ fontSize: '11px', marginTop: '8px' }}>
          This email address will be the username for TSC UP and The Blend. It must
          be unique and cannot be associated to any other users.
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          fullWidth
          control={control}
          id="city"
          name="city"
          label={strings.get('label.city')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          formControlProps={{
            fullWidth: true,
          }}
          options={statesOptions}
          control={control}
          id="state"
          name="state"
          label={strings.get('label.state-province')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCheckbox
          control={control}
          id="is_admin"
          name="is_admin"
          label={strings.get('label.is-admin')}
        />
      </Grid>
    </FormSection>
  );
};

export default SupportContactSection;
