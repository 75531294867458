import { useQuery } from '@tanstack/react-query';
import { IAbove } from 'modules/Dashboard/interfaces';

import aboveService from 'services/aboveService';

export interface IUseGetAboveUnitsProps {
  list_name: string;
  dummyCall?: boolean;
}

const getAboveUnits = async ({
  list_name,
  dummyCall, // added so a call to the backend is not made if the user doesn't have privs
}: IUseGetAboveUnitsProps): Promise<IAbove[]> => {
  if(dummyCall===true) return [] as IAbove[];
  return await aboveService.getListAboves(list_name);
};

const useGetAboveUnits = ({ list_name, dummyCall }: IUseGetAboveUnitsProps) => {
  const { data, error, isLoading, isFetching } = useQuery(['above_units'], () =>
    getAboveUnits({ list_name,dummyCall }),
  );

  return { data, error, loading: isLoading || isFetching };
};

export default useGetAboveUnits;
