import { EmploymentStatus } from 'enums';

export const ROLE_EMPLOYMENT_STATUS_MAP = {
  'Above Cafe Operations': EmploymentStatus.Salaried,
  'Franchisee': EmploymentStatus.Salaried,
  'Crew Member': EmploymentStatus.Hourly,
  'Shift Leader': EmploymentStatus.Hourly,
  'Assistant Manager': EmploymentStatus.Hourly,
  'General Manager': EmploymentStatus.Hourly,
};

export const SUPPORT_ROLE_EMPLOYMENT_STATUS_MAP = {
  'Coordinator': EmploymentStatus.Salaried,
  'Specialist': EmploymentStatus.Salaried,
  'Manager': EmploymentStatus.Salaried,
  'Senior Manager': EmploymentStatus.Salaried,
  'Director': EmploymentStatus.Salaried,
  'Senior Director': EmploymentStatus.Salaried,
  'Vice President': EmploymentStatus.Salaried,
  'Senior Vice President': EmploymentStatus.Salaried,
  'Executive': EmploymentStatus.Salaried,
  'Contractor': EmploymentStatus.Hourly,
};
