import { Typography, Button, Box, Link } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetRecoilState } from 'recoil';
import { authAtom } from 'atoms';
import { Message, FormTextField, PasswordTextField } from 'components';
import { AccountType } from 'enums';
import { authService } from 'services';
import { FORMS } from '../enums';
import strings from '../../../configs/strings';
import { ActionBtnStyles } from './../../../global-styles';
import tscUpLogo from './../../../assets/tsc-up-logo.png';

const formRow = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0 15px',
};

const initialState = {
  email: '',
  password: '',
};

type FormKeys = typeof initialState;

interface ILoginForm {
  setForm: (form: FORMS) => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required('An email is required!'),
  password: yup.string().required('A password is required!'),
});

const LoginForm: React.FC<ILoginForm> = ({ setForm }) => {
  const setAuth = useSetRecoilState(authAtom);
  const [error, setError] = useState('');
  const { control, handleSubmit } = useForm<FormKeys>({
    defaultValues: initialState,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormKeys) => {
    const { email, password } = data;

    try {
      await authService.login(email, password);
      const user = await authService.getCurrentUser();
      setAuth({
        isAuth: true,
        user: user,
      });
      console.log('user.accountType', user.accountType);
      if (user.accountType === AccountType.support) {
        console.log('i want to go to the support page');
        //navigate('/supports');
      } else {
        console.log("i'm going to the reg dashboard");
        //navigate('/');
      }
    } catch (error) {
      console.log('login failed');
      setError(error.message || error.email);
      return;
    }
  };

  const onForgotPasswordClick = () => setForm(FORMS.RESET_PASSWORD);

  return (
    <>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          component="img"
          alt="tsc logo"
          sx={{
            display: 'block',
            margin: '0 auto',
            marginBottom: '12px',
            height: '131px',
          }}
          src={tscUpLogo}
        />
        <Typography variant="h3" mb={4} fontWeight={500} color="customColors.sea">
          Hey there!
        </Typography>
        <Typography variant="body1" component="p" mb={2} fontSize={16}>
          Welcome to the Tropical Smoothie Cafe<sup>&reg;</sup> User Provisioning
          Platform - or UP for short! Please log in to continue.
        </Typography>
        <Box
          sx={{
            ...formRow,
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <FormTextField
            id="email"
            control={control}
            name="email"
            type="email"
            sx={{ flex: 1 }}
            label={strings.get('label.email')}
          />
          <PasswordTextField
            id="password"
            control={control}
            type="password"
            name="password"
            sx={{ flex: 1 }}
            label={strings.get('label.password')}
          />
        </Box>

        {error && (
          <Message variant="error" sx={{ marginBottom: '10px' }}>
            {error}
          </Message>
        )}

        <Box sx={formRow}>
          <Link
            onClick={onForgotPasswordClick}
            sx={{
              fontWeight: 'bold',
              color: '#047e8b',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <Typography
              color={'customColors.punch'}
              fontWeight={'bold'}
              sx={{ fontSize: '1rem' }}
            >
              Forgot Password?
            </Typography>
          </Link>
          <Button type="submit" sx={ActionBtnStyles}>
            {strings.get('btn.sign-in')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
