export enum AboveUnitUserRole {
  above_cafe_operations = 'Above Cafe Operations',
  franchisee = 'Franchisee',
}

export enum RegularUnitUserRole {
  crew_member = 'Crew Member',
  shift_leader = 'Shift Leader',
  assistant_manager = 'Assistant Manager',
  general_manager = 'General Manager',
}

export enum EmployeeRole {
  crew_member = 'Crew Member',
  shift_leader = 'Shift Leader',
  assistant_manager = 'Assistant Manager',
}

export enum UserRole {
  all_roles = 'all_roles',
  crew_member = 'Crew Member',
  shift_leader = 'Shift Leader',
  assistant_manager = 'Assistant Manager',
  general_manager = 'General Manager',
  above_cafe_operations = 'Above Cafe Operations',
  franchisee = 'Franchisee',
}

export enum LowerUserRole {
  crew_member = 'Crew Member',
  shift_leader = 'Shift Leader',
  assistant_manager = 'Assistant Manager',
}

export enum UpperUserRole {
  general_manager = 'General Manager',
  above_cafe_operations = 'Above Cafe Operations',
  franchisee = 'Franchisee',
}

export enum SupportRole {
  contractor = 'Contractor',
  coordinator = 'Coordinator',
  specialist = 'Specialist',
  manager = 'Manager',
  senior_manager = 'Senior Manager',
  director = 'Director',
  senior_director = 'Senior Director',
  vice_president = 'Vice President',
  senior_vice_president = 'Senior Vice President',
  executive = 'Executive',
  fbl = 'Franchise Business Leader',
  senior_fbl = 'Sr. Franchise Business Leader',
  regional_director = 'Regional Director',
  head_of_regional_director = 'Head of Regional Director',
  //tech_support = 'Technical Support'
}

export enum AdminRole {
  administrator = 'administrator',
}

export enum UserType {
  //admin = 'admin',
  cafe = 'cafe',
  support_center = 'support_center',
}

export enum AccountType {
  admin = 'admin',
  lowerUser = 'lowerUser',
  upperUser = 'upperUser',
  support = 'support',
}

export enum AccountStatus {
  'active' = 'active',
  'deactivated' = 'deactivated',
}

export enum EmploymentStatus {
  Hourly = 'Hourly',
  Salaried = 'Salaried',
}

export enum States {
  alabama = 'Alabama',
  alaska = 'Alaska',
  arizona = 'Arizona',
  arkansas = 'Arkansas',
  california = 'California',
  connecticut = 'Connecticut',
  florida = 'Florida',
  georgia = 'Georgia',
  illinois = 'Illinois',
  indiana = 'Indiana',
  kansas = 'Kansas',
  kentucky = 'Kentucky',
  maine = 'Maine',
  michigan = 'Michigan',
  minnesota = 'Minnesota',
  new_york = 'New_york',
  ohio = 'Ohio',
  oklahoma = 'Oklahoma',
  texas = 'Texas',
  utah = 'Utah',
  washington = 'Washington',
}

export enum DepartmentConstants {
  cafe_operations = 'Cafe Operations',
} 

export enum CafeOperationsDepartment {
  'Cafe Operations' = 'Cafe Operations',
}

export enum SupportCenterUserExecutiveRoles {
  'Senior Vice President' = 'Senior Vice President',
  'Executive' = 'Executive',
}

export enum AboveCafeUserRole {
  'Above Cafe Operations' = 'Above Cafe Operations',
  'Franchisee' = 'Franchisee',
}
