const dropDownSearchBarStyles = {
  minWidth: '205px',
    '.MuiInputLabel-formControl, .MuiSelect-iconOutlined, .MuiInputBase-formControl': {
        color: 'customColors.sea',
        '&.MuiInputLabel-shrink': {
          color: 'customColors.sky',
        }
      },
      '.MuiInputBase-root.MuiInputBase-formControl': {
        '.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline': {
          borderColor: 'customColors.sky',
        },
        '&:hover': {
          borderColor: 'customColors.turquoise',
        },
        '&:focus': {
          color:  'customColors.sky',
        },
      },
}

export default dropDownSearchBarStyles;