import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo, useCallback, useState, useRef, useEffect } from 'react';

import { AccountStatus } from 'enums';
import SupportsHeader from '../components/SupportsHeader';
import SupportsFilter from '../components/SupportsFilter';
import SupportsTable from '../tables/SupportsTable';
import { useSupports, useFilter, useSortModel } from '../hooks';
import { ISupportsFilter } from '../interfaces';
import Toast, { ToastType } from 'components/Toast/Toast';

import ActionCell from '../tables/ActionCell';
import EditCell from '../tables/EditCell';

interface ISupportsPageProps {
  accountStatus: AccountStatus;
}

const SupportsPage: React.FC<ISupportsPageProps> = ({
  accountStatus: account_status,
}) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastType, setToastType] = useState(null);
  const [toastMessage, setToastMessage] = useState('');

  const mounted = useRef<boolean>(false);
  const { filter, onFilterChange } = useFilter<Partial<ISupportsFilter>>();
  const { sortModel, onSortModelChange } = useSortModel();
  const { data, loading, activateMutation, deactivateMutation, reFetch, onSuccess } = useSupports({
    account_status,
    filter,
    sortModel,
  });

  useEffect(() => {
    if (!mounted.current) {
      // refetch data on init/when component is not mounted
      reFetch();
    }
    return () => { mounted.current = true; }
  }, [reFetch]);

  const activationSuccess = useCallback((activationStatus: string) => {
    onSuccess();
    setIsToastOpen(true);
    setToastType(ToastType.success);
    setToastMessage(`User successfully ${activationStatus}d!`);
  }, [onSuccess])

  const activationError = (activationStatus: string) => {
    setIsToastOpen(true);
    setToastType(ToastType.error);
    setToastMessage(`Error occurred during ${activationStatus}!`);
  }

  const actions = useMemo(
    (): GridColDef[] => [
      {
        field: `_edit`,
        headerName: 'Edit',
        sortable: false,
        renderCell: (cellProps) => (
          <EditCell href={(uuid) => `/support/update/${uuid}`} {...cellProps} />
        ),
      },
      {
        field: `_support-action`,
        headerName:
          account_status === AccountStatus.active ? 'Deactivate' : 'Reactivate',
        sortable: false,
        renderCell: (cellProps) => (
          <ActionCell
            text={
              account_status === AccountStatus.active ? 'Deactivate' : 'Reactivate'
            }
            onClick={(uuid) =>
              account_status === AccountStatus.active
                ? deactivateMutation.mutate(uuid, { onSuccess: () => activationSuccess('deactivate'), onError: () => activationError('deactivation') })
                : activateMutation.mutate(uuid, { onSuccess: () => activationSuccess('reactivate'),  onError: () => activationError('reactivation') })
            }
            {...cellProps}
          />
        ),
      },
    ],
    [account_status, activateMutation, activationSuccess, deactivateMutation],
  );

  return (
    <Box height="100%" maxWidth="100%">
      <SupportsHeader accountStatus={account_status} />
      <SupportsFilter onChange={onFilterChange} />
      <SupportsTable
        actions={actions}
        data={data}
        sortModel={sortModel}
        loading={loading}
        onSortModelChange={onSortModelChange}
      />
       <Toast
        isOpen={isToastOpen}
        setIsOpen={setIsToastOpen}
        message={toastMessage}
        type={toastType}
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default SupportsPage;
