import { Typography, Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import strings from "../../../configs/strings";
import { ActionBtnStyles } from '../../../global-styles';

const PasswordUpdateSuccess = () => {
  const navigate = useNavigate();

  const onBack = () => navigate('/login');

  return (
    <Container>
      <Typography variant="h4" color="customColors.pomegranate">{strings.get('label.reset-password-success')}</Typography>
      <Button onClick={onBack} sx={ActionBtnStyles}>
        {strings.get('btn.go-to')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 10px 0;

  > button {
    margin-top: 15px;
  }
`;

export default PasswordUpdateSuccess;
