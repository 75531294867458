import * as R from 'ramda';
import { useController, UseControllerProps } from 'react-hook-form';

import SelectChips, { ISelectChipsProps } from './SelectChips';
import { ISelectChipsV2Props } from './SelectChipsV2';

export interface IFormChipsSelectProps
  extends Omit<ISelectChipsProps, 'value' | 'onChange'>,
    UseControllerProps<any> {
      ChipComponent?: React.FC<ISelectChipsProps | ISelectChipsV2Props>
    }

// TODO: this component is almost fully copy pasted from FormSelect.tsx which is not great I think
const FormChipsSelect: React.FC<IFormChipsSelectProps> = ({
  name,
  control,
  rules,
  defaultValue = [],
  formControlProps = {},
  selectProps = {},
  ChipComponent = SelectChips,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const { ref } = field;

  return (
    <ChipComponent
      {...R.omit(['ref', 'onChange'], field)}
      onChange={(event) => {
        field.onChange(event.sort());
      }}
      selectProps={{
        ...selectProps,
        inputRef: ref,
      }}
      formControlProps={{
        ...formControlProps,
        error: fieldState.error,
      }}
      {...rest}
    />
  );
};

export default FormChipsSelect;
