import { useQuery } from '@tanstack/react-query';
import { IJob } from 'modules/Dashboard/interfaces';

import jobService from 'services/jobService';

export interface IUseGetJobsProps {
  list_name: string;
}

const getJobs = async ({ list_name }: IUseGetJobsProps): Promise<IJob[]> => {
  return await jobService.getListJobs(list_name);
};

const useGetJobs = ({ list_name }: IUseGetJobsProps) => {
  const { data, error, isLoading, isFetching } = useQuery(['jobs'], () =>
    getJobs({ list_name }),
  );

  return { data: data || [], error, loading: isLoading || isFetching };
};

export default useGetJobs;
