import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { authAtom } from 'atoms';
import { FormSelect, FormCheckbox } from 'components';
import { EmploymentStatus, UserRole, AccountType } from 'enums';
import { EMPLOYMENT_STATUS_DISPLAY_MAP } from 'maps';
import { getOptions } from 'utils';
import { UseUserFormReturn } from '../hooks/useUserForm';
import FormSection from '../layout/FormSection';
import { ROLE_EMPLOYMENT_STATUS_MAP } from '../maps';
import useGetListAboveUnits from '../queries/useGetListAboveUnits';
import useGetListJobs from '../queries/useGetListJobs';
import { IAbove, IJob } from 'modules/Dashboard/interfaces';
import strings from "../../../configs/strings";

interface IUserOrganizationSectionProps {
  control: UseUserFormReturn['control'];
  setValue: UseUserFormReturn['setValue'];
  watch: UseUserFormReturn['watch'];
}

const UserOrganizationSection: React.FC<IUserOrganizationSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  const { user } = useRecoilValue(authAtom);
  const isAdmin = user?.accountType === AccountType.admin 

  const { data: aboveUnits } = useGetListAboveUnits({ list_name: 'decentralized', dummyCall:!isAdmin});
  const { data: jobs } = useGetListJobs({ list_name: 'cafe' });

  const role = watch('role');
  const decentralizedCafes = watch('decentralized_cafes');

  const roleOptions = useMemo(
    //() => getOptions(Object.keys(UserRole), LEARNER_ROLE_DISPLAY_MAP),
    //[],
    () =>
      jobs?.filter((job:IJob)=>{
        if(isAdmin) return true;
        else if(job.name !== 'Franchisee') return true;
        else return false;
      }).map((job: IJob) => ({
        value: job.name,
        label: job.name,
      })) || [],
    [jobs, isAdmin],
  );

  const employmentStatusOptions = useMemo(
    () => getOptions(Object.keys(EmploymentStatus), EMPLOYMENT_STATUS_DISPLAY_MAP),
    [],
  );

  const decentralizedCafesOptions = useMemo(
    //() => getOptions(user.decentralizedCafes, EMPLOYMENT_STATUS_DISPLAY_MAP),
    //[user],
    () =>
      aboveUnits?.map((above: IAbove) => ({
        value: above.name,
        label: above.name,
      })) || [],
    [aboveUnits],
  );

  useEffect(() => {
    setValue('employment_status', ROLE_EMPLOYMENT_STATUS_MAP[role]);

    if (role !== UserRole.franchisee) {
      setValue('decentralized_cafes', '');
      setValue('is_head_of_decentralized_cafes', false);
    }
  }, [role, setValue]);

  const isFranchisee = role === UserRole.franchisee;
  const isDecentralizedCafeSelected =
    decentralizedCafes && decentralizedCafes !== 'None';

  return (
    <FormSection title="Organization">
      <Grid item marginTop={2} xs={6}>
        <FormSelect
          formControlProps={{
            fullWidth: true,
            required: true,
          }}
          options={roleOptions}
          control={control}
          id="role"
          name="role"
          label={strings.get('label.learner-role')}
        />
      </Grid>
      <Grid item marginTop={2} xs={6}>
        <FormSelect
          formControlProps={{
            fullWidth: true,
            required: true,
          }}
          options={employmentStatusOptions}
          control={control}
          id="employment_status"
          name="employment_status"
          label={strings.get('label.employment-status')}          
        />
        <small><em>Hourly employees will be required to take their courses at the cafe to avoid off the clock work.</em></small>
      </Grid>
      {isFranchisee && isAdmin && (
        <Grid item xs={6}>
          <FormSelect
            formControlProps={{
              fullWidth: true
            }}
            options={decentralizedCafesOptions}
            control={control}
            id="decentralized_cafes"
            name="decentralized_cafes"
            label={strings.get('label.decentralized-cafes')}
          />
        </Grid>
      )}
      {isFranchisee && isAdmin && isDecentralizedCafeSelected && (
        <Grid item xs={6}>
          <FormCheckbox
            control={control}
            name="is_head_of_decentralized_cafes"
            label={strings.get('label.decentralized-cafes-manager')}
          />
        </Grid>
      )}
    </FormSection>
  );
};

export default UserOrganizationSection;
