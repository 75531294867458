import { nanoid } from 'nanoid';
import React from 'react';
import * as R from 'ramda';
import {
  TextField,
  Chip,
  Autocomplete,
  AutocompleteProps,
  MenuItem,
  FormControl,
  FormControlProps,
  FormHelperText,
  ListSubheader
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { SelectAutocompleteFormControlStyles, SelectAutocompleteStyles, dropDownMenuStyles } from './../../global-styles/SelectAutocomplete';

const LISTBOX_PADDING = 8; // px

interface IFormControlProps extends Omit<FormControlProps, 'error'> {
  error?: {
    message?: string;
  };
}
export interface IAutocompleteProps<T> {
  id: string;
  options: T[];
  label: string;
  value: T | T[] | string[];
  onChange: (e: object, v: any) => void;
  formControlProps?: IFormControlProps;
}

export type FormSelectAutocompleteProps<T> = IAutocompleteProps<T> &
Omit<AutocompleteProps<T, boolean, boolean, boolean>, 'renderInput'>


function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <MenuItem
    component="li"
    key={`select-autocomplete-${nanoid()}`}
    value={dataSet[1]?.value || dataSet[1]}
    {...R.omit(['data', 'isScrolling'], props)}
    className="autocomplete-input-menu-item"
    {...dataSet[0]}
    sx={{...inlineStyle, ...dropDownMenuStyles}}
  >
     {dataSet[1]?.label || dataSet[1]}
  </MenuItem>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const SelectAutocomplete = <
  T extends {},
>({
  id,
  label,
  options,
  value = [],
  onChange,
  formControlProps = {},
  ...rest
}: FormSelectAutocompleteProps<T>) => {

  return (
    <FormControl
      error={!!formControlProps.error}
      {...(R.omit(['error'], formControlProps) as Record<string, any>)}
      sx={{...SelectAutocompleteFormControlStyles, ...formControlProps?.sx}}
    >
    <Autocomplete
      className="autocomplete"
      id={id}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={(option: any) => option?.label || option}
      multiple
      disableCloseOnSelect
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.label || option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          className="autocomplete-input"
          InputLabelProps={{
            className: "autocomplete-input-label" 
          }}
        />
      )}

      ListboxComponent={ListboxComponent}
      renderOption={(props, option, state) => [props, option, state] as React.ReactNode
      }
      {...rest}
      sx={{...SelectAutocompleteStyles}}
    />
    {formControlProps.error && (
      <FormHelperText sx={{ mx: 0 }}>
        {formControlProps.error.message}
      </FormHelperText>
    )}
  </FormControl>
  );
};

export default SelectAutocomplete;
