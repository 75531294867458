import { createTheme } from '@mui/material';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from './mui-module-aug';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Arial",
      "sans serif"
    ].join(",")
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& > *': {
            fontSize: '1.1rem !important',
          }
        },
      },
      defaultProps: {
        size: 'small',
        sx: {
          minWidth: 205,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '1rem',
          '&, .MuiDataGrid-columnHeaders, .MuiDataGrid-row .MuiDataGrid-cell, .MuiDataGrid-footerContainer': {
            borderColor: theme.palette.customColors.sky,
          },
          '.MuiDataGrid-columnHeaders': {
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 900,
            },
            '.MuiDataGrid-iconSeparator, .MuiDataGrid-sortIcon': {
              color: theme.palette.customColors.turquoise
            },
          },
          '.MuiDataGrid-row .MuiDataGrid-cell': {
            '&[data-field=_edit] a, &[data-field=_support-action] span, &[data-field=email] a': {
              color: theme.palette.customColors.sea
            },
            '&[data-field=_edit], &[data-field=_support-action]': {
              cursor: 'pointer',
              fontWeight: 500,
            },
            '&[data-field=_edit] a': {
              textDecoration: 'none',
            },
            '&[data-field=email] a': {
              overflowWrap: 'anywhere'
            }
          },
          '.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: `solid ${theme.palette.customColors.pomegranate} 1px`,
            }
          },
          '.MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.customColors.sky,
          },
          '.MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '15px',
            paddingBottom: '15px',
          },
        }),
      },
      defaultProps: {
        disableColumnMenu: true,
        disableSelectionOnClick: true,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
          fontWeight: 500,
          fontSize: '1.1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
        fontFamily: [
          "Montserrat",
          "Arial",
          "sans serif"
        ].join(",")
      }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 600
        },
        h2: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 600
        },
        h3: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 500
        },
        h4: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 500
        },
        h5: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 500
        },
        h6: {
          fontFamily: [
            "Montserrat",
            "Arial",
            "sans serif"
          ].join(","),
          fontWeight: 500
        }
      }
    }
  },
  palette: {
    customColors: {
      tropical: '#8A941E',
      smoothie: '#FCB515',
      cafe: '#F26322',
      sunrise: '#ffba00',
      sunset: '#ff6900',
      sea: '#057d8f',
      turquoise: '#4ccfe8',
      pomegranate: '#cf0082',
      sundown: '#ff4200',
      kiwi: '#82cf00',
      sky: '#73ebf2',
      punch: '#ff008c',
      seaTint: '#d6edf2',
      metallicSeaweed: '#047E8B',
      vividCerulean: '#00a3e0',
      springBud: '#a0ff00',
      romanSilver: "#87929F"
    },
  },
});

export default theme;
