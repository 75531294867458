import { useMutation } from '@tanstack/react-query';
import { IUser } from 'modules/Dashboard/interfaces';

import { userService } from 'services';

const updateUser = async ({ userId, data }) => {
  if (!userId) return null;
  return await userService.updateUser({ userId, data });
};

interface IUseUpdateUserOptions {
  userId: string | undefined;
  data: Partial<IUser>;
}

const useUpdateUser = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useMutation({
    mutationFn: ({ userId, data }: IUseUpdateUserOptions) =>
      updateUser({ userId, data }),
  });

  return { mutate, isLoading, isError, isSuccess, error };
};

export default useUpdateUser;
