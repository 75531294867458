import React, { useState } from 'react';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Grid, Button } from '@mui/material';

import { FormChipsSelect, FormTextField } from 'components';
import { UseUserFormReturn } from '../hooks/useUserForm';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import { ActionBtnStyles } from '../../../global-styles';
import strings from '../../../configs/strings';
// import { IOption } from 'interfaces';
import { IUnit } from '../interfaces';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormSelectAutocomplete } from './../../../components/Selects';

interface ICafeNumberDDSectionProps {
  control: UseUserFormReturn['control'] | UseSupportFormReturn['control'];
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  cafeOptions: IUnit['name'][];
}

const CafeNumberDDSection: React.FC<ICafeNumberDDSectionProps> = ({
  control,
  setValue,
  cafeOptions,
  watch,
}) => {
  const [showBulkSearch, setShowBulkSearch] = useState(false);

  const unitsDisplay = watch('unitsDisplay');
  const bulkUnitsDisplay = watch('bulk_units_display');

  const handleSearchToggle = () => {
    setShowBulkSearch(!showBulkSearch);
  };

  const appendCafeNumbers = () => {
    // seperate pasted cafe numbers by new line, space or comma
    const providedUnitsDisplay = bulkUnitsDisplay?.trim()?.split(/[ \n,]+/);

    if (providedUnitsDisplay?.length > 0) {
      // create an object named selectedObj that contains the currently selected values
      const selectedObj = {};

      for (const el of unitsDisplay) {
        if (el) {
          selectedObj[el] = el;
        }
      }

      // create an object of valid cafe values (cafeNumbersObj)
      const cafeNumbersObj = {};

      for (const cafeNumVal of cafeOptions) {
        if (cafeNumVal) {
          cafeNumbersObj[cafeNumVal] = cafeNumVal;
        }
      }

      // if providedUnitsDisplay value is not already selected AND is a valid cafe value then add it
      const newUnitsDisplay = providedUnitsDisplay.filter((provUnit) => {
        const isNotSelectedAndIsValidCafeNumber =
          !selectedObj[provUnit] && cafeNumbersObj[provUnit];

        // add to selectedObj if not already there
        if (provUnit) {
          selectedObj[provUnit] = provUnit;
        }

        return isNotSelectedAndIsValidCafeNumber;
      });

      // append form values to unitsDisplay
      setValue('unitsDisplay', unitsDisplay.concat(newUnitsDisplay).sort());

      // clear after bulk append
      setValue('bulk_units_display', '');
    }

    handleSearchToggle();
  };

  return (
    <>
      {showBulkSearch && (
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <FormTextField
            multiline
            maxRows={1}
            rows={4}
            control={control}
            name="bulk_units_display"
            label={strings.get('label.list-bulk-cafe-nums')}
            variant="outlined"
            placeholder="Add cafe numbers seperated by newlines, spaces or commas."
            sx={{
              flexBasis: '80%',
              paddingRight: '10px',
              '.MuiInputBase-root.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline':
                { borderColor: 'customColors.sky' },
            }}
          />
          <Button
            onClick={appendCafeNumbers}
            sx={{
              ...ActionBtnStyles,
              flexBasis: '20%',
              alignSelf: 'flex-start',
              height: '50px',
            }}
          >
            {strings.get('btn.add-cafe')}
          </Button>
        </Grid>
      )}
      <Grid item marginTop={2} xs={12} sx={{ display: 'flex' }}>
        {/* <FormChipsSelect
          formControlProps={{
            required: true,
            sx: {
              width: '80%',
              paddingRight: '10px',
            },
          }}
          options={cafeOptions}
          control={control}
          id="unitsDisplay"
          name="unitsDisplay"
          label={strings.get('label.cafe-number')}
        /> */}
        <FormSelectAutocomplete
          formControlProps={{
            required: true,
            sx: {
              width: '80%',
              paddingRight: '10px',
            },
          }}
          options={cafeOptions.sort()}
          control={control}
          id="unitsDisplay"
          name="unitsDisplay"
          label={strings.get('label.cafe-number')}
        />
        <Button
          onClick={handleSearchToggle}
          sx={{
            color: 'customColors.romanSilver',
            flexBasis: '25%',
            height: '50px',
            flexDirection: 'row',
            '&:hover': {
              color: 'black',
            },
          }}
        >
          <CloudDownloadOutlinedIcon sx={{ mr: '10px' }} />
          {` `}
          {strings.get('btn.bulk-add')}
        </Button>
      </Grid>
      <Grid item marginTop={2} xs={12} sx={{ display: 'flex' }}>
        <small>
          <em>{strings.get('dd.disclaimer')}</em>
        </small>
      </Grid>
    </>
  );
};

export default CafeNumberDDSection;
