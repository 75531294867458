
import {  useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, FormFooter, FormHeader } from '../layout';
import Toast, { ToastType } from 'components/Toast/Toast';
import strings from 'configs/strings';
import { Typography, Grid} from '@mui/material';
import {FormTextField} from 'components';
import {adminService} from 'services';

const TestEmailForm = () => {
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastType, setToastType] = useState(ToastType.success);
    const [toastMessage, setToastMessage] = useState('');
    const [toastAutoHideDuration, setToastAutoHideDuration] = useState(3000);
    const {control, handleSubmit} = useForm({});
    
    const onSubmit = async (data: any) => {
        const {emails} = data;
        console.log(data);
        if(!emails || emails==="") return;

        const emails_ary = emails.split(";");
        const results = await adminService.testEmails(emails_ary);
        console.log("results:", results);

        let success = false;
        let errors = [];
        let errorMessage = "";
        if(results?.status === "success") success = true;
        else{
            errors = [...results?.errors];
            if(errors && errors.length>0){
                for(const e of errors){
                    errorMessage += e?.email + ": " + e?.message + "\n";
                }
            }
        }
       
       

        setIsToastOpen(true);
        setToastMessage(success ? strings.get("label.test-email-form.success") : errorMessage);
        setToastType(success ? ToastType.success : ToastType.error);
        setToastAutoHideDuration(success ? 3000 : null);
    }
    return (
        <Container onSubmit={handleSubmit(onSubmit)}>
            <FormHeader title={strings.get('label.test-email-form.title')} />
            <Typography variant="body1" fontSize={16} marginTop={1.5}>
                {strings.get('label.test-email-form.instructions')}
            </Typography>
                <Grid item xs={12}>
                    <FormTextField
                    fullWidth
                    control={control}
                    id="emails"
                    name="emails"
                    label={strings.get('label.test-email-form.emails')}
                    />
                </Grid>
          <FormFooter submitLabel="Send" />
          {
          <Toast
            isOpen={isToastOpen}
            setIsOpen={setIsToastOpen}
            message={toastMessage}
            type={toastType}
            autoHideDuration={toastAutoHideDuration}
          />}
        </Container>
      );
    };
    
  export default TestEmailForm;
    