import { Grid, Box } from '@mui/material';

import { PasswordTextField } from 'components';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import { UseUserFormReturn } from '../hooks/useUserForm';
import { UseUpdateUserProfileFormReturn } from '../hooks/useUpdateUserProfileForm';
import FormSection from '../layout/FormSection';
import strings from '../../../configs/strings';

interface IUserPasswordSectionProps {
  control: UseUserFormReturn['control'] | UseSupportFormReturn['control'] | UseUpdateUserProfileFormReturn['control'];
}

const passwordRules = [
  'Minimum length: 8 characters',
  'Maximum length: 64 characters',
  'At least one uppercase character',
  'At least one lowercase character',
  'Not more than three identical characters in a row (e.g., aaa or 111)',
  'First and last name as part of the password is not permitted',
  "the term \"Login\" as password is not permitted",
  'Password cannot match academy names',
];

const UserPasswordSection: React.FC<IUserPasswordSectionProps> = ({ control }) => {
  return (
    <FormSection title="Password">
      <Grid item xs={12}>
        <PasswordTextField
          fullWidth
          control={control}
          id="newPassword"
          name="newPassword"
          type="password"
          label={strings.get('label.new-password')}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordTextField
          fullWidth
          control={control}
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label={strings.get('label.verify-password')}
        />
      </Grid>
      <Grid item xs={12}>
        <Box component="ul" sx={{pl: '1rem'}}>
          {passwordRules.map((rule, index) => <Box component="li" key={`password-rules-${index}`}>{rule}</Box>)}
        </Box>
      </Grid>
    </FormSection>
  );
};

export default UserPasswordSection;
