import * as yup from 'yup';
import '../addedYupMethods/passwordValidator';


const newPassword = yup.string().when('newPassword', (val) => {
    if (val?.length > 0) {
      return yup.string().passwordValidator().required();
    } else {
      return yup.string().optional();
    }
});

export default newPassword;