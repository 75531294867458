import { UserRole, EmploymentStatus, SupportRole } from 'enums';

export const LEARNER_ROLE_DISPLAY_MAP = {
  [UserRole.all_roles]: 'All Roles',
  [UserRole.crew_member]: 'Crew Member',
  [UserRole.shift_leader]: 'Shift Leader',
  [UserRole.assistant_manager]: 'Assistant Manager',
  [UserRole.general_manager]: 'General Manager',
  [UserRole.above_cafe_operations]: 'Above Cafe Operations',
  [UserRole.franchisee]: 'Franchisee',
};

export const SUPPORT_LEARNER_ROLE_DISPLAY_MAP = {
  [SupportRole.contractor]: 'Contractor',
  [SupportRole.coordinator]: 'Coordinator',
  [SupportRole.specialist]: 'Specialist',
  [SupportRole.manager]: 'Manager',
  [SupportRole.senior_manager]: 'Senior Manager',
  [SupportRole.director]: 'Director',
  [SupportRole.senior_director]: 'Senior Director',
  [SupportRole.vice_president]: 'Vice President',
  [SupportRole.senior_vice_president]: 'Senior Vice President',
  [SupportRole.executive]: 'Executive',
};

export const EMPLOYMENT_STATUS_DISPLAY_MAP = {
  [EmploymentStatus.Hourly]: 'Hourly',
  [EmploymentStatus.Salaried]: 'Salaried',
};
