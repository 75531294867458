import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { EmploymentStatus } from 'enums';
import { ISupportFormFields } from '../interfaces';
import useGetUser from '../queries/useGetUser';

import { newPassword } from '../Yup/commonValidators';

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  city: yup.string().nullable().optional(),
  state: yup.string().nullable().optional(),
  is_admin: yup.boolean(),
  role: yup.string().required(),
  employment_status: yup.string().equals(Object.values(EmploymentStatus)).required(),
  department: yup.string().required(),
  is_head_of_department: yup.boolean(),
  team: yup.string().optional(),
  unitsDisplay: yup.array().of(yup.string()),
  newPassword,
  confirmPassword: yup
    .string()
    .optional()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
}, [['newPassword', 'newPassword']]);

const useSupportForm = () => {
  const { userId } = useParams<'userId'>();
  const { data, loading } = useGetUser({ userId });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    getValues,
  } = useForm<ISupportFormFields>({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!loading) {
      reset(data);
    }
  }, [data, loading, reset]);

  const isEdit = !!userId;

  return {
    control,
    errors,
    isEdit,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    getValues,
  };
};

export type UseSupportFormReturn = ReturnType<typeof useSupportForm>;

export default useSupportForm;
