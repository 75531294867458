import { useQuery } from '@tanstack/react-query';

import { STALE_TIME } from '../constants';

import authService from "../../../services/authService";

type resetTokenType = string;

const getUserByToken = async (resetToken: resetTokenType) => {
  if (!resetToken) return null;
  return await authService.getUserByToken(resetToken);
};

export const useGetUserByToken = (resetToken: resetTokenType) => {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    error
  } = useQuery(
    {
      queryKey: ["userByToken", resetToken],
      queryFn: () => getUserByToken(resetToken),
      keepPreviousData: true,
      staleTime: STALE_TIME,
      placeholderData: { data: {}},
    },
  );

  return { data, loading: isLoading || isFetching, isError, error };
};
