import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { authAtom } from 'atoms';
import { useRecoilValue } from 'recoil';
import { AccountType, UserType } from 'enums';
import { authService } from 'services';

import useSupportForm from '../hooks/useSupportForm';
import useCreateUser from '../queries/useCreateUser';
import useUpdateUser from '../queries/useUpdateUser';

import { DASHBOARD_USERS_QUERY_KEY } from 'modules/Dashboard/queries';
import { Container, FormFooter, FormHeader } from '../layout';
import SupportContactSection from '../sections/SupportContactSection';
import SupportOrganizationSection from '../sections/SupportOrganizationSection';
import UserPasswordSection from '../sections/UserPasswordSection';
import { Message } from 'components';
import { objHasValues } from '../../../utils/objHasValues';

const filterSelectValues = (options: string[]) =>
  (options || []).map((options) => {
    const parsedObj = JSON.parse(options);
    return parsedObj.value;
  });

const MessageStyles = { fontSize: '20px' }; 

const SupportForm = () => {
  const [status, setStatus] = useState({ type: '', msg: '' });
  // const [isToastOpen, setIsToastOpen] = useState(false);
  // const [toastType, setToastType] = useState(ToastType.success);
  // const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userId } = useParams<'userId'>();

  const { user } = useRecoilValue(authAtom);
  const { isEdit, errors, control, setValue, reset, handleSubmit, watch, getValues } =
    useSupportForm();

  const { mutate: createUser } = useCreateUser();
  const { mutate: updateUser } = useUpdateUser();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [DASHBOARD_USERS_QUERY_KEY] });
    setStatus({ type: 'success', msg: 'User Updated!' });
    if (!isEdit) {
      reset();
    }
  }, [queryClient, reset, isEdit]);

  const onSuccessEdit = () => {
    onSuccess();
    //go to previous page
    // navigate(-1);
  };

  const onError = (err: unknown) => {
    // create toast error message if error is present
    const errMsg = (err as Error)?.message;
    if (err) {
      setStatus({ type: 'error', msg: errMsg });
    }
  };

  const onCancel = () => {
    navigate('/active-supports');
  };

  const onSubmit = async (data: any) => {
    try {
      const {
        newPassword,
        confirmPassword,
        password,
        fbl,
        regional_director,
        senior_fbl,
        ...formData
      } = data;

      const fblList = filterSelectValues(fbl);
      const regionalDirector = filterSelectValues(regional_director);
      const seniorFbl = filterSelectValues(senior_fbl);

      if ((newPassword || confirmPassword) && newPassword === confirmPassword) {
        await authService.updatePasswordByAdmin({
          password: newPassword,
          passwordConfirmation: confirmPassword,
          adminId: user.uuid,
          userId: formData.uuid,
        });
      }
      if (isEdit) {
        updateUser(
          {
            userId,
            data: {
              ...formData,
              fbl: fblList,
              regional_director: regionalDirector,
              senior_fbl: seniorFbl,
              //department: formData.department?.split('/')[0],
              //team: formData.team?.split('/')[0],
            },
          },
          { onSuccess: onSuccessEdit, onError },
        );
      } else {
        createUser(
          {
            ...formData,
            fbl: fblList,
            regional_director: regionalDirector,
            senior_fbl: seniorFbl,
            user_type: UserType.support_center,
            //department: formData.department?.split('/')[0],
            //team: formData.team?.split('/')[0],
          },
          { onSuccess, onError },
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // if status is successful, redirect after 2 seconds
  useEffect(() => {
    if (status.type !== 'success') return;

    const timeId = setTimeout(() => {
      // After 2 seconds redirect to active users page
      navigate('/active-supports');
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, [navigate, status.type]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <FormHeader title={`${isEdit ? 'Update' : 'New'} Support Center User`} />
      <SupportContactSection control={control} />
      <SupportOrganizationSection
        control={control}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
        reset={reset}
        isEdit={isEdit}
      />
      {isEdit && user.accountType === AccountType.admin && (
        <UserPasswordSection control={control} />
      )}

      {status.type === 'error' && (
        <Message variant="error" sx={MessageStyles}>
          {status.msg}
        </Message>
      )}
      {status.type === 'success' && !objHasValues(errors) && (
        <Message variant="success" sx={MessageStyles}>
          {status.msg}
        </Message>
      )}

      <FormFooter onCancel={onCancel} submitLabel={isEdit ? 'Save' : 'Create'} />
    </Container>
  );
};

export default SupportForm;
