import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { authAtom } from 'atoms';
import { useRecoilValue } from 'recoil';
import { AccountType, UserType, UpperUserRole, UserRole } from 'enums';
import { authService } from 'services';

import useUserForm from '../hooks/useUserForm';
import useCreateUser from '../queries/useCreateUser';
import useUpdateUser from '../queries/useUpdateUser';

import { DASHBOARD_USERS_QUERY_KEY } from 'modules/Dashboard/queries';
import { Container, FormFooter, FormHeader } from '../layout';
import UserContactSection from '../sections/UserContactSection';
import UserOrganizationSection from '../sections/UserOrganizationSection';
import UserDesignatedOperatorSection from '../sections/UserDesignatedOperatorSection';
import UserCafeDetailsSection from '../sections/UserCafeDetailsSection';
import UserPasswordSection from '../sections/UserPasswordSection';
//import useGetAboveTypeByName from '../queries/useGetAboveTypeByName';
import { Message } from 'components';
import strings from 'configs/strings';
import { objHasValues } from '../../../utils/objHasValues';

const MessageStyles = { fontSize: '20px' }; 

const UserForm = () => {
  const [status, setStatus] = useState({type: '', msg: ''});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userId } = useParams<'userId'>();
  const [isWaiting, setIsWaiting] = useState(false);

  const { user } = useRecoilValue(authAtom);
  const {
    isEdit,
    control,
    dirtyFields,
    setValue,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    errors,
  } = useUserForm();

  const { mutate: createUser } = useCreateUser();
  const { mutate: updateUser } = useUpdateUser();

  const learnerRole = watch('role');
  //const isUpperRole = (Object.values(UpperUserRole).includes(learnerRole));

  //const learnerRole = watch('role') || 'crew_member';
  //const { data: aboveType } = useGetAboveTypeByName(learnerRole);

  const onSuccess = useCallback(() => {
    setIsWaiting(false);
    queryClient.invalidateQueries({ queryKey: [DASHBOARD_USERS_QUERY_KEY] });
    // setIsToastOpen(true);
    setStatus({ type: 'success', msg: 'User Updated!' });
    // setToastDuration(3000);
    if (!isEdit) {
      reset();
    }
  }, [queryClient, reset, isEdit]);

  const roleIncludedInList = ( role: UserRole) :boolean => {
    const upperRoles = Object.values(UpperUserRole) as string[];
    return upperRoles.includes(role.toString());
    //return false
  }

  //console.log("========= Is Upper Role: " + roleIncludedInList(learnerRole));
  const onSuccessEdit = () => {
    setIsWaiting(false);
    onSuccess();
    //go to previous page
    // navigate(-1);
  };

  const onError = (err: unknown) => {
    // create toast error message if error is present
    let errMsg = (err as Error)?.message;
    setIsWaiting(false);
    if (err) {
      if (errMsg === 'User with that email already exists!')
        errMsg = strings.get('errors.email-in-use');
      else if (errMsg === undefined || errMsg === '')
        errMsg = strings.get('errors.unknown');
      
      setStatus({ type: 'error', msg: errMsg });
    }
  };

  const onCancel = () => {
    console.log('Cancel clicked');
    navigate('/');
  };

  const onSubmit = async (data: any) => {
    console.log('Cafe Form Submitted');
    setIsWaiting(true);
    try {
      const { newPassword, confirmPassword, password, ...formData } = data;
      if ((newPassword || confirmPassword) && newPassword === confirmPassword) {
        await authService.updatePasswordByAdmin({
          password: newPassword,
          passwordConfirmation: confirmPassword,
          adminId: user.uuid,
          userId: formData.uuid,
        });
        //for admin password update, update formData to include the clearing of failed logins
        formData['failed_login_attempts'] = 0;
      }
      console.log('formData:', formData);
      if (isEdit) {
        updateUser(
          {
            userId,
            data: { ...formData },
          },
          { onSuccess: onSuccessEdit, onError },
        );
      } else {
        const correctData = {
          ...formData,
          user_type: UserType.cafe,
          aboves: [],
        };

        createUser(correctData, { onSuccess, onError });
      }
    } catch (error) {
      if (isEdit) console.error('Error updating user:', error);
      else console.error('Error creating user', error);
      onError(error);
    }
  };

  // if status is successful, redirect after 2 seconds
  useEffect(() => {
    if (status.type !== 'success') return;

    const timeId = setTimeout(() => {
      // After 2 seconds redirect to active users page
      navigate('/active-users')
    }, 2000)

    return () => {
      clearTimeout(timeId)
    }
  }, [navigate, status.type]);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <FormHeader title={`${isEdit ? 'Update' : 'New'} Cafe User`} />
      <UserContactSection
        control={control}
        isEdit={isEdit}
        dirtyFields={dirtyFields}
        setError={setError}
        reset={reset}
        clearErrors={clearErrors}
      />
      <UserOrganizationSection control={control} setValue={setValue} watch={watch} />
      <UserCafeDetailsSection
        control={control}
        setValue={setValue}
        watch={watch}
        reset={reset}
        isEdit={isEdit}
      />
      {roleIncludedInList(learnerRole) && (
        <UserDesignatedOperatorSection control={control} setValue={setValue} watch={watch} />
      )}
      {isEdit && user.accountType === AccountType.admin && (
        <UserPasswordSection control={control} />
      )}

      {status.type === 'error' && (
        <Message variant="error" sx={MessageStyles}>
          {status.msg}
        </Message>
      )}
      {status.type === 'success' && !objHasValues(errors) && (
        <Message variant="success" sx={MessageStyles}>
          {status.msg}
        </Message>
      )}

      <FormFooter
        onCancel={onCancel}
        submitLabel={isEdit ? 'Save' : 'Create'}
        isWaiting={isWaiting}
      />
    </Container>
  );
};

export default UserForm;
