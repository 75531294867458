import * as R from 'ramda';
import { useState, useCallback, useEffect, useMemo, ReactNode } from 'react';
import { AutocompleteRenderInputParams, Button } from '@mui/material';

//import useGetCafeNumbers from 'modules/Forms/queries/useGetCafeNumbers';
import useGetStoreCafes from 'modules/Forms/queries/useGetStoreCafes';
import { Select, SelectChips, SelectAutocomplete } from 'components';
import { UserRole } from 'enums';
import { omitEmpty, getUnitOptions } from 'utils';
import { IUsersFilter, ISearchFilter, IJob } from '../interfaces';
import FiltersContainer from './FiltersContainer';
import SearchInput from './SearchInput';
import useGetListJobs from 'modules/Forms/queries/useGetListJobs';
import { ActionBtnStyles } from './../../../global-styles';
import strings from '../../../configs/strings';
import { useRecoilValue } from 'recoil';
import { authAtom } from 'atoms';
import { AccountType } from 'enums';
// import { getOptions } from '../../../utils';
// import { IOption } from '../../../interfaces';

interface IUsersFilterProps {
  onChange: (filter: Partial<IUsersFilter>) => any;
}

const initialState: IUsersFilter = {
  role: '' as UserRole,
  cafe_number: [],
  search: '',
};

const UsersFilter: React.FC<IUsersFilterProps> = ({ onChange }) => {
  const { user } = useRecoilValue(authAtom);
  const isAdmin = user?.accountType === AccountType.admin 
  const {
    data: jobs,
  } = useGetListJobs({ list_name: 'cafe' });
  const [filter, setFilter] = useState(initialState);
  const [searchInput, setSearchInput] = useState('');

  // console.log(filter?.cafe_number);
  const roleOptions = useMemo(
    //() => getOptions(Object.keys(UserRole), LEARNER_ROLE_DISPLAY_MAP),
    //[],
    () =>
      jobs.filter((job:IJob)=>{
        if(isAdmin) return true;
        else if(job.name !== 'Franchisee') return true;
      }).map((job: IJob) => ({
        value: job.name,
        label: job.name,
      })),
    [isAdmin, jobs],
  );

  const {
    data: cafeNumbers,
  } = useGetStoreCafes();

  // const cafeNumberOptions = useMemo(
  //   () => getUnitOptions(cafeNumbers),
  //   [cafeNumbers],
  // );

  const cafeNumberArr = cafeNumbers.map((cafe) => cafe.name);

  const onRoleChange = useCallback((role: UserRole) => {
    //console.log('==== UsersFilter.tsx: onRoleChange:' + role);
    setFilter(R.assoc('role', role));
    if (role === 'all_roles') {
      setFilter(R.assoc(null, role));
    }
  }, []);

  const onCafeNumberChange = useCallback((cafe_number: string[]) => {
    // const formattedCafeNums = getOptions(cafe_number);
    setFilter(R.assoc('cafe_number', cafe_number));
  }, []);

  const onSearchChange = useCallback((searchFilter: Partial<ISearchFilter>) => {
    setFilter(R.mergeLeft(searchFilter));
  }, []);

  const resetAllFilters = () => {
    setSearchInput('');
    onSearchChange(initialState);
  };

  useEffect(() => {
    onChange(omitEmpty(filter));
  }, [filter, onChange]);

  return (
    <FiltersContainer>
      <Select
        id="user-role"
        label={strings.get('label.learner-role')}
        options={roleOptions}
        value={filter?.role}
        onChange={onRoleChange}
      />

      <SelectAutocomplete 
        id="user-cafe-number"
        label={strings.get('label.cafe-number')}
        options={cafeNumberArr.sort()}
        value={filter?.cafe_number}
        onChange={(__, v) => onCafeNumberChange(v.sort())}    
        // disabled={cafeNumberArr.length === 0}
        />
      <SearchInput
        value={searchInput}
        setValue={setSearchInput}
        onChange={onSearchChange}
      />
      <Button
        sx={{
          height: '39px',
          ...ActionBtnStyles,
        }}
        variant="contained"
        onClick={resetAllFilters}
      >
        {strings.get('btn.reset')}
      </Button>
    </FiltersContainer>
  );
};

export default UsersFilter;
