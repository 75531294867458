import client from 'configs/client';

class JobService {
  public async getListJobs(list_name: string) {
    const { data: jobs } = await client.get(`/dashboard/jobs/list/${list_name}`);
    return jobs;
  }
}

const jobService = new JobService();

export default jobService;
