import { useQuery } from '@tanstack/react-query';

import { userService } from 'services';
// import { userDefaultValues } from '../constants';

const fetchUser = async ({ role }: IUseGetUserOptions) => {
  // if (!userId) return Promise.resolve(userDefaultValues);
  if (!role) return null;
  return await userService.fetchUserByRole({ role });
};

interface IUseGetUserOptions {
  // should be undefined when creating a user to set empty default values inside form
  role: string | undefined;
}

const useGetUsersByRole = ({ role }: IUseGetUserOptions) => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['role', role],
    () => fetchUser({ role }),
    // {
    //   placeholderData: userDefaultValues
    // }
  );

  return { data, error, loading: isLoading || isFetching };
};

export default useGetUsersByRole;
