import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { authAtom } from 'atoms';
import { AccountStatus } from 'enums';
import UsersHeader from '../components/UsersHeader';
import UsersFilter from '../components/UsersFilter';
import UsersTable from '../tables/UsersTable';
import { useUsers, useFilter, usePagination, useSortModel } from '../hooks';
import { IUsersFilter } from '../interfaces';
import ActionCell from '../tables/ActionCell';
import EditCell from '../tables/EditCell';
import { EmployeeRole } from '../../../enums/users';
import Toast, { ToastType } from 'components/Toast/Toast';

interface IUsersPageProps {
  accountStatus: AccountStatus;
}

const UsersPage: React.FC<IUsersPageProps> = ({ accountStatus: account_status }) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastType, setToastType] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  
  const mounted = useRef<boolean>(false);
  const { filter, onFilterChange } = useFilter<Partial<IUsersFilter>>();
  const { page, pageSize, onPageChange, onPageSizeChange } = usePagination();
  const { sortModel, onSortModelChange } = useSortModel();
  const { user: { role } } = useRecoilValue(authAtom);

  const { data, loading, rowCount, deactivateMutation, activateMutation, reFetch, onSuccess } = useUsers({
    account_status,
    filter,
    page,
    pageSize,
    sortModel,
  });

  useEffect(() => {
    if (!mounted.current) {
      // refetch data on init/when component is not mounted
      reFetch();
    }
    return () => { mounted.current = true; }
  }, [reFetch]);


  const isNotEmployeeRole = !(Object.values(EmployeeRole) as string[]).includes(role);

  const activationSuccess = useCallback((activationStatus: string) => {
    onSuccess();
    setIsToastOpen(true);
    setToastType(ToastType.success);
    setToastMessage(`User successfully ${activationStatus}d!`);
  }, [onSuccess])

  const activationError = (activationStatus: string) => {
    setIsToastOpen(true);
    setToastType(ToastType.error);
    setToastMessage(`Error occurred during ${activationStatus}!`);
  }

  const actions = useMemo(
    (): GridColDef[] => isNotEmployeeRole ? [
      {
        field: `_edit`,
        headerName: 'Edit',
        sortable: false,
        renderCell: (cellProps) => (
          <EditCell href={(uuid) => `/user/update/${uuid}`} {...cellProps} />
        ),
      },
      {
        field: `_support-action`,
        headerName:
          account_status === AccountStatus.active ? 'Deactivate' : 'Reactivate',
        sortable: false,
        renderCell: (cellProps) => (
          <ActionCell
            text={
              account_status === AccountStatus.active ? 'Deactivate' : 'Reactivate'
            }
            onClick={(uuid) =>
              account_status === AccountStatus.active
                ? deactivateMutation.mutate(uuid, { onSuccess: () => activationSuccess('deactivate'), onError: () => activationError('deactivation') })
                : activateMutation.mutate(uuid, { onSuccess: () => activationSuccess('reactivate'),  onError: () => activationError('reactivation') })
            }
            {...cellProps}
          />
        ),
      },
    ] : [],
    [account_status, activateMutation, activationSuccess, deactivateMutation, isNotEmployeeRole],
  );

  return (
    <Box height="100%" maxWidth="100%">
      <UsersHeader accountStatus={account_status} />
      <UsersFilter onChange={onFilterChange} />
      <UsersTable
        actions={actions}
        data={data}
        loading={loading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        sortModel={sortModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
      />
      <Toast
        isOpen={isToastOpen}
        setIsOpen={setIsToastOpen}
        message={toastMessage}
        type={toastType}
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default UsersPage;
