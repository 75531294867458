const dropDownMenuStyles = {
      '.MuiMenuItem-root': {
          '&.Mui-selected:hover, &:hover': {
            backgroundColor: 'customColors.turquoise',
          },
          '&:target, &[aria-selected=true], &.Mui-focusVisible': {
            backgroundColor: 'customColors.sky',
          }, 
      }
}

export default dropDownMenuStyles;