import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { FormChipsSelect, SelectChipsV2 } from 'components';
//import { AccountStatus } from 'enums';
import { SupportRole } from 'enums';
import { IUser } from 'modules/Dashboard/interfaces';
//import { useGetUsers } from 'modules/Dashboard/queries';
import useGetUsersByRole from 'modules/Forms/queries/useGetUsersByRole';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import FormSection from '../layout/FormSection';
import strings from '../../../configs/strings';

interface ISupportOrganizationSectionProps {
  control: UseSupportFormReturn['control'];
  setValue: UseSupportFormReturn['setValue'];
  watch: UseSupportFormReturn['watch'];
}

const SupportOperationsUsersSection: React.FC<ISupportOrganizationSectionProps> = ({
  control,
  // setValue,
  // watch,
}) => {
  // temporary
  const { data: regionalDirectors } = useGetUsersByRole({
    role: SupportRole.head_of_regional_director,
  });

  const regionalDirectorOptions = useMemo(
    () =>
      regionalDirectors?.map((user: IUser) => ({
        value: user.schoox_id,
        //label: `${user.firstname} ${user.lastname}`,
        label: `${user.firstname} ${user.lastname}`,
      })) || [],
    [regionalDirectors],
  );
  const { data: seniorFbls } = useGetUsersByRole({
    role: SupportRole.senior_fbl,
  });

  const seniorManagerOptions = useMemo(
    () =>
      seniorFbls?.map((user: IUser) => ({
        value: user.schoox_id,
        //label: `${user.firstname} ${user.lastname}`,
        label: `${user.firstname} ${user.lastname}`,
      })) || [],
    [seniorFbls],
  );
  const { data: fbls } = useGetUsersByRole({
    role: SupportRole.fbl,
  });

  const managerOptions = useMemo(
    () =>
      fbls?.map((user: IUser) => ({
        value: user.schoox_id,
        //label: `${user.firstname} ${user.lastname}`,
        label: `${user.firstname} ${user.lastname}`,
      })) || [],
    [fbls],
  );

  return (
    <FormSection title="Operations Users">
      <Grid item marginTop={2} xs={6}>
        <FormChipsSelect
          formControlProps={{
            fullWidth: true,
          }}
          options={managerOptions}
          control={control}
          id="fbl"
          name="fbl"
          label={strings.get('label.franchise-business-leaders')}
          ChipComponent={SelectChipsV2}
        />
      </Grid>
      <Grid item marginTop={2} xs={6}>
        <FormChipsSelect
          formControlProps={{
            fullWidth: true,
          }}
          options={seniorManagerOptions}
          control={control}
          id="senior_fbl"
          name="senior_fbl"
          label={strings.get('label.sr-franchise-business-leaders')}
          ChipComponent={SelectChipsV2}
        />
      </Grid>
      <Grid item marginTop={2} xs={6}>
        <FormChipsSelect
          formControlProps={{
            fullWidth: true,
          }}
          options={regionalDirectorOptions}
          control={control}
          id="regional_director"
          name="regional_director"
          label={strings.get('label.regional-director')}
          ChipComponent={SelectChipsV2}
        />
      </Grid>
    </FormSection>
  );
};

export default SupportOperationsUsersSection;
