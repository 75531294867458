import { useSetRecoilState } from 'recoil';
import { ArrowBack } from '@mui/icons-material';
import { Typography, IconButton, Grid, Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { authAtom } from 'atoms';
import { Message, PasswordTextField } from 'components';
import { authService } from 'services';
import {
  Form,
  FormRow,
  FormBody,
  FormHeader,
  PasswordUpdateSuccess,
} from '../components';
import * as yup from 'yup';
import strings from "../../../configs/strings";
import { useForm } from 'react-hook-form';
import { ActionBtnStyles } from '../../../global-styles';
import { newPassword } from './../../Forms/Yup/commonValidators';
import { useGetUserByToken } from './../../Dashboard/queries';

import { updatePasswordFormInitialValues } from './../../Dashboard/constants';


export interface IUserProfileFormFields {
  firstname: string;
  lastname: string;
  newPassword: string;
  confirmPassword: string;
}


const passwordRules = [
  'Minimum length: 8 characters',
  'Maximum length: 64 characters',
  'At least one uppercase character',
  'At least one lowercase character',
  'Not more than three identical characters in a row (e.g., aaa or 111)',
  'First and last name as part of the password is not permitted',
  "the term \"Login\" as password is not permitted",
  'Password cannot match academy names',
];

const schema = yup.object().shape({
  newPassword,
  confirmPassword: yup
    .string()
    .optional()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
}, [['newPassword', 'newPassword']]);


const UpdatePasswordForm = () => {
  const { search } = useLocation();
  const setAuth = useSetRecoilState(authAtom);
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const { data, isError: tokenIsError, error: tokenErr } = useGetUserByToken(token);

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const {
    control,
    handleSubmit,
    reset
  } = useForm<IUserProfileFormFields>({
    defaultValues: updatePasswordFormInitialValues,
    resolver: yupResolver(schema),
  });

  // format the data received by useGetUserByToken and reset the form manually
  useEffect(() => {
    if (data) {
      const formValues: IUserProfileFormFields = {
        firstname: data.firstname,
        lastname: data.lastname,
        newPassword: '',
        confirmPassword: '',
      };
      reset(formValues)
    }
  }, [data, reset])

  const onSubmit = async (data: IUserProfileFormFields) =>{
  setError('');
console.log(data);
    if (!token) return setError('No Token Provided!');

    try {
      const { newPassword, confirmPassword } = data;
      await authService.updatePassword(newPassword, confirmPassword, token);
      reset({...updatePasswordFormInitialValues, firstname: data.firstname, lastname: data.lastname});
      setSuccess(strings.get('label.reset-password-success'));
      setAuth({ isAuth: false, user: null });
    } catch (error) {
      let message = strings.get('errors.unknown');
      if(typeof error === "string") message = error;
      else if(error){
        if(error.message) message = error.message;
        else if(error.newPassword) message = error.newPassword;
        else if(error.confirmPassword) message = error.confirmPassword;
      }
      setError(message);
      setSuccess('');
    }
  }

  const onBack = () => navigate('/login');

  if (success) return <PasswordUpdateSuccess />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader>
        <Typography variant="h3" color="customColors.pomegranate">{strings.get('label.reset-password')}</Typography>
        <IconButton onClick={onBack}>
          <ArrowBack htmlColor="#000" fontSize="large" />
        </IconButton>
      </FormHeader>
      <Typography variant="body1" fontSize={16} marginTop={1.5}>
        {strings.get('label.reset-password-text')}
      </Typography>
      <FormBody>
        {/*<FormSection title="Password">*/}
          <Grid item xs={12}>
            <PasswordTextField
            type="password"
            fullWidth
            control={control}
            id="newPassword"
            name="newPassword"
            label={strings.get('label.new-password')}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
            type="password"
            fullWidth
            control={control}
            id="confirmPassword"
            name="confirmPassword"
            label={strings.get('label.verify-password')}
            />
          </Grid>
          <br/>
          <Grid item xs={12}>
            <Box component="ul" sx={{pl: '1rem'}}>
            {passwordRules.map((rule, index) => <Box component="li" key={`password-rules-${index}`}>{rule}</Box>)}
            </Box>
          </Grid>
        {/*</FormSection>*/}
      </FormBody>

      {(tokenIsError || error) && <Message variant="error">{(tokenErr as Error)?.message || error}</Message>}
      <FormRow>
        <span />
        <Button type="submit" sx={ActionBtnStyles}>
          {strings.get('btn.submit')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default UpdatePasswordForm;
