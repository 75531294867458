import client from 'configs/client';
import { omitEmpty } from 'utils';

class UnitService {
  public async getUnits(filter: { department: string }) {
    const query = this.convertToQueryParams(filter);
    const { data: units } = await client.get(`/dashboard/units?${query}`);
    return units;
  }

  public async getListUnits(list_name: string) {
    const { data: units } = await client.get(`/dashboard/units/list/${list_name}`);

    return units;
  }

  private convertToQueryParams = (params: Record<string, any>) => {
    return Object.entries(omitEmpty(params))
      .map((kv) => {
        if (Array.isArray(kv[1])) kv[0] += '[]';
        return kv.map(encodeURIComponent).join('=');
      })
      .join('&');
  };
}

const unitService = new UnitService();

export default unitService;
