import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { dashboardService } from 'services';
import { AccountStatus } from 'enums';
import { useGetSupports, DASHBOARD_SUPPORTS_QUERY_KEY } from '../queries';
import { ISupportsFilter, ISortModel } from '../interfaces';

interface IUseSupports {
  account_status: AccountStatus;
  filter: Partial<ISupportsFilter>;
  sortModel: ISortModel;
}

const useSupports = ({ account_status, filter, sortModel }: IUseSupports) => {
  const queryClient = useQueryClient();
  const { data, loading } = useGetSupports({
    filter,
    account_status,
    sortModel,
  });

  const onSuccess = useCallback(() =>
    queryClient.invalidateQueries({ queryKey: [DASHBOARD_SUPPORTS_QUERY_KEY] }), [queryClient]);

  const deactivateMutation = useMutation({ mutationFn: dashboardService.deactivateSupport });

  const activateMutation = useMutation( {mutationFn: dashboardService.activateSupport});

  const reFetch = useCallback(() => onSuccess(), [onSuccess]);

  return {
    data,
    loading,
    deactivateMutation,
    activateMutation,
    reFetch,
    onSuccess,
  };
};

export default useSupports;
