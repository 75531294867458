import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import strings from "../../configs/strings";

import { CancelBtnStyles, ActionBtnStyles } from './../../global-styles';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  titleText?: string;
  bodyText?: string;
  onSuccess: () => void;
  confirmText: string;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  titleText = 'Are you sure?',
  bodyText = '',
  onSuccess,
  confirmText = 'Confirm',
}: Props) => {
  const handleClose = () => setIsOpen(false);
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {titleText}
        </Typography>
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          {bodyText}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ minWidth: '110px', mx: 1, ...CancelBtnStyles }}
          >
            {strings.get('btn.cancel')}
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onSuccess}
            sx={{ minWidth: '110px', mx: 1, ...ActionBtnStyles }}
          >
            {confirmText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
