import { pullObjectPropertyFromArr } from 'utils';
import { UseUserFormReturn } from '../hooks/useUserForm';
import FormSection from '../layout/FormSection';
import useGetStoreCafes from '../queries/useGetStoreCafes';
import CafeNumberDDSection from './CafeNumberDDSection';

interface IUserCafeDetailsSectionProps {
  control: UseUserFormReturn['control'];
  setValue: UseUserFormReturn['setValue'];
  watch: UseUserFormReturn['watch'];
  reset: UseUserFormReturn['reset'];
  isEdit: UseUserFormReturn['isEdit'];
}

const UserCafeDetailsSection: React.FC<IUserCafeDetailsSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  const { data: storeCafes } = useGetStoreCafes();
  const cafeNumbersOptions = pullObjectPropertyFromArr(storeCafes, 'name');

  return (
    <FormSection title="Cafe Details">
      <CafeNumberDDSection control={control} setValue={setValue} watch={watch} cafeOptions={cafeNumbersOptions} />
    </FormSection>
  );
};

export default UserCafeDetailsSection;
