import { useQuery } from '@tanstack/react-query';

import { cafeService } from 'services';
import { IUnit } from 'modules/Dashboard/interfaces';

const getStoreCafes = async (): Promise<IUnit[]> => {
  return await cafeService.getStoreCafes();
};

const useGetStoreCafes = () => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['get_store_cafes'],
    () => getStoreCafes(),
    { placeholderData: [] },
  );
  return { data, error, loading: isLoading || isFetching };
};

export default useGetStoreCafes;
