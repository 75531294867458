import client from 'configs/client';
import { Buffer } from "buffer";

class DocumentService {
  public async listDocumentsForUser() {
    const { data: files } = await client.get(`/dashboard/documents/list`);
    return files;
  }

  public async getFileForDownload(filename: string) {
    const { data: filedata } = await client.get(
      `/dashboard/documents/download/${filename}`,
    );
    // Parse the json payload and extract the blob data sent by the backend controller
    const parsed = JSON.parse(filedata);
    const fileBlob = parsed.blob;
    // Restore buffer data and decode the blob
    let bufferData = Buffer.from(fileBlob, "base64");
    // Return the new buffer to the web page where it will be converted into a Blob and downloaded;
    return bufferData;
  }













  
  public async getFileForDownloadCopy(filename: string) {
    const { data: filedata } = await client.get(
      `/dashboard/documents/download/${filename}`,
    );
    console.log("Downloaded file: ");
    console.log(filedata);
    return filedata;
  }
}

const documentService = new DocumentService();

export default documentService;
