import client from 'configs/client';

class AdminService {
    public async testEmails(emails: String[]): Promise<any> {
        return client.post('/admin/test-emails', emails)
        .then(()=>{
            return {status:"success"}
        })
        .catch(errs=>{
            const errors=[];
            for(const key in errs) errors.push(errs[key]);
            return {status:"failed", errors}
        })
    }
}

const adminService = new AdminService();

export default adminService;