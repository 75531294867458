import list from './strings.json';

class Strings {
  public get(str: string): string {
    return list[str] ? list[str] : '';
  }
}

const strings = new Strings();

export default strings;
