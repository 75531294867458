import { useMemo } from 'react';
import { UseSupportFormReturn } from '../hooks/useSupportForm';
import FormSection from '../layout/FormSection';
import useGetStoreCafes from '../queries/useGetStoreCafes';
//import useGetCafeNumbers from '../queries/useGetCafeNumbers';
import CafeNumberDDSection from './CafeNumberDDSection';
import { pullObjectPropertyFromArr } from 'utils';

interface ISupportOrganizationSectionProps {
  control: UseSupportFormReturn['control'];
  setValue: UseSupportFormReturn['setValue'];
  watch: UseSupportFormReturn['watch'];
}

const SupportCafeDetailsSection: React.FC<ISupportOrganizationSectionProps> = ({
  control,
  setValue,
  watch,
}) => {
  const { data: storeCafes } = useGetStoreCafes();
  
  /* 
    This watch was added as a quickfix for the rerendering issue for the CafeNumberDDSection
    rerendering issue. It appears alot of the support form data was not initalized correctly.
    This is likely what is causing issue with the dropdown. The entire Support form needs to
    be refactored.
  */
  useMemo(() => {
    watch();
  }, [watch])

  const cafeNumbersOptions = pullObjectPropertyFromArr(storeCafes, 'name');

  return (
    <FormSection title="Cafe Details">
      <CafeNumberDDSection control={control} setValue={setValue} watch={watch} cafeOptions={cafeNumbersOptions} />
    </FormSection>
  );
};

export default SupportCafeDetailsSection;
